import React from "react";
import {
  Box,
  IconButton,
  Typography,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Tooltip,
  Divider,
  Drawer,
  Badge,
} from "@mui/material";
import { mainNavBarItems } from "./consts/navBarItems";
import { mainNavHelpItems } from "./consts/navHelp";
import { useNavigate } from "react-router";
import MailIcon from "@mui/icons-material/Mail";

const FullScreenDrawer = ({ isDrawerOpen, toggleDrawer, badgeNo }) => {
  const navigate = useNavigate();

  const handleClick = (route) => {
    navigate(`${route}`);
    toggleDrawer();
  };

  const year = new Date().getFullYear();
  const copyrightText = `© ${year} Cypher™. All rights reserved.`;

  const updatedNavBarItems = mainNavBarItems.map((item) => {
    if (item.id === 1) {
      return {
        ...item,
        icon: (
          <Badge badgeContent={badgeNo} color="error">
            <MailIcon color="red" />
          </Badge>
        ),
      };
    }
    return item;
  });

  return (
    <>
      <Drawer
        open={isDrawerOpen}
        anchor="right"
        PaperProps={{
          sx: {
            marginTop: "13%",
          },
        }}
      >
        <Box
          sx={{
            width: "95%",

            background:
              "linear-gradient(135deg, #0f172a, #1e293b, #374151, #111827)",
            color: "#fff",
            zIndex: 1200,
            transform: isDrawerOpen ? "translateX(0)" : "translateX(100%)",
            transition: "transform 0.6s ease-in-out",
            overflowY: "auto",
            display: "flex",
            flexDirection: "column",
            padding: 3,
          }}
        >
          <Typography
            variant="h6"
            sx={{
              marginBottom: 3,

              borderBottom: "2px solid rgba(255, 255, 255, 0.3)",
              paddingBottom: 1,
            }}
          >
            Case Management
          </Typography>
          <List>
            {updatedNavBarItems.map((item) => (
              <Tooltip
                key={item.id}
                title={`Quick access to ${item.label}`}
                placement="right"
              >
                <ListItem
                  button
                  onClick={() => handleClick(item.route)}
                  sx={{
                    marginBottom: 2,
                    width: "97%",
                    padding: 2,
                    backgroundColor: "rgba(255, 255, 255, 0.05)",
                    borderRadius: 2,
                    transition: "all 0.4s ease-in-out",
                    "&:hover": {
                      backgroundColor: "rgba(255, 255, 255, 0.15)",
                      transform: "scale(1.05)",
                    },
                  }}
                >
                  <ListItemIcon sx={{ color: "#60a5fa" }}>
                    {item.icon}
                  </ListItemIcon>
                  <ListItemText
                    primary={
                      <Typography variant="body1">{item.label}</Typography>
                    }
                    secondary={
                      <Typography
                        variant="caption"
                        sx={{ color: "rgba(255, 255, 255, 0.7)" }}
                      >
                        {`${item.helper} `}
                      </Typography>
                    }
                  />
                </ListItem>
              </Tooltip>
            ))}
          </List>

          <Divider
            sx={{ marginY: 4, backgroundColor: "rgba(255, 255, 255, 0.5)" }}
          />

          {/* Help Section */}
          <Typography
            variant="h6"
            sx={{
              marginBottom: 3,

              borderBottom: "2px solid rgba(255, 255, 255, 0.3)",
              paddingBottom: 1,
            }}
          >
            Help & Support
          </Typography>
          <List>
            {mainNavHelpItems.map((item) => (
              <Tooltip
                key={item.id}
                title={`Get assistance with ${item.label}`}
                placement="right"
              >
                <ListItem
                  button
                  onClick={() => handleClick(item.route)}
                  sx={{
                    width: "97%",
                    marginBottom: 2,
                    padding: 2,
                    backgroundColor: "rgba(255, 255, 255, 0.05)",
                    borderRadius: 2,
                    transition: "all 0.4s ease-in-out",
                    "&:hover": {
                      backgroundColor: "rgba(255, 255, 255, 0.15)",
                      transform: "scale(1.05)",
                    },
                  }}
                >
                  <ListItemIcon sx={{ color: "#34d399" }}>
                    {item.icon}
                  </ListItemIcon>
                  <ListItemText
                    primary={
                      <Typography variant="body1">{item.label}</Typography>
                    }
                    secondary={
                      <Typography
                        variant="caption"
                        sx={{ color: "rgba(255, 255, 255, 0.7)" }}
                      >
                        {`${item.helper} `}
                      </Typography>
                    }
                  />
                </ListItem>
              </Tooltip>
            ))}
          </List>

          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              marginBottom: "7%",
            }}
          >
            <p>{copyrightText}</p>
          </div>
        </Box>
      </Drawer>
    </>
  );
};

export default FullScreenDrawer;
