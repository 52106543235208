import HelpIcon from "@mui/icons-material/Help";
import GpsNotFixedIcon from "@mui/icons-material/GpsNotFixed";
import ConstructionIcon from "@mui/icons-material/Construction";
import WorkHistoryIcon from "@mui/icons-material/WorkHistory";
import LogoutIcon from "@mui/icons-material/Logout";
import ApiIcon from "@mui/icons-material/Api";
export const mainNavHelpItems = [
  {
    id: 0,
    icon: <HelpIcon />,
    label: "Help Desk",
    route: "help-desk",
    helper:
      "Get detailed instructions on how to add, edit or interprete cases and pk score",
  },
  {
    id: 1,
    icon: <GpsNotFixedIcon />,
    label: "Fraud Tips",
    route: "fraudtips",
    helper: "Template tips to identify and avoid fraud",
  },
  {
    id: 3,
    icon: <ConstructionIcon />,
    label: "Cypher Tools",
    route: "cyphertools",
    helper:
      "Core and third party tools used in ip-tracing and tracking attempts",
  },

  {
    id: 5,
    icon: <WorkHistoryIcon />,
    label: "History",
    route: "history",
    helper:
      "Reel through our work together as we continously create a safe space for you",
  },
];
