import React, { useEffect, useState } from "react";
import GridWrapper from "../../../common/GridWrapper/GridWrapper2";
import BasicCard from "../../../common/BasicCard/BasicCard";
import Typography from "@mui/material/Typography";
import CaseList from "../Cases/CasesComponent/CaseList";
import ProductHome from "./ProductsHome";
import { toast } from "react-toastify";
import { Button } from "@mui/material";
import { useNavigate } from "react-router";
import UserCaseStats from "./UserCaseStats";
import TraceDataDisplay from "./TraceDataDisplay";
import RecoveryBar from "./RecoveryBar";
import { getCases } from "../../../services/cases";
import { getTraceData } from "../../../services/traceData";
import { getProducts } from "../../../services/products";
import Loader1 from "../Loaders/Loader1";

const HomeMobile = () => {
  const navigate = useNavigate();
  const [caselist, setCaselist] = useState([]);
  const [products, setProducts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [traces, setTraces] = useState({});
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);

      try {
        const response = await getTraceData();
        setTraces(response.data);
        const [casesResponse, productsResponse] = await Promise.all([
          getCases(),
          getProducts(),
        ]);

        setCaselist(casesResponse.data);
        setProducts(productsResponse.data);
      } catch (err) {
        setError("Failed to fetch data.");
        console.error(err);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  if (loading) {
    return <Loader1 />;
  }
  const getcases = () => {
    if (caselist.length) return <CaseList />;
    else return getContent();
  };
  const getContent = () => (
    <Typography
      align="center"
      sx={{
        margin: "2.5rem 1rem",
        color: "rgba(0, 0, 0, 0.6)",
        fontSize: "1.3rem",
      }}
    >
      No active cases <br />
      <Button
        style={{ marginTop: "1rem" }}
        onClick={() => navigate("/account/add-case")}
        variant="contained"
      >
        Add
      </Button>
    </Typography>
  );
  const productClick = () => {
    toast.info("API endpoints are for commercial use only");
  };
  return (
    <GridWrapper>
      <BasicCard content={getcases()} />
      <UserCaseStats caselist={caselist} /> <RecoveryBar />
      <TraceDataDisplay traceData1={traces} />
      {products &&
        products.map((p) => (
          <ProductHome products={p} productClick={productClick} />
        ))}
    </GridWrapper>
  );
};

export default HomeMobile;
