import "../landingstyles.css";
import React, { useState } from "react";

import NewRegister from "./NewRegister";

const Register = () => {
  return (
    <div>
      <NewRegister />
    </div>
  );
};

export default Register;
