import { faInbox } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Avatar, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router";
import { getInbox, patchInbox } from "../../../../services/inbox";
import Loader4 from "../../Loaders/Loader4";

const InboxMobileData = () => {
  const [messages, setMessages] = useState(null);
  const [loading, setLoading] = useState(true);
  const { id } = useParams();
  useEffect(() => {
    const fetchMessage = async () => {
      try {
        const response = await getInbox(id);
        setMessages(response.data);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching case data:", error);
      }
    };

    fetchMessage();
  }, [id]);

  if (loading) {
    return (
      <div>
        <Loader4 />
      </div>
    );
  }
  const handlePatch = async (id) => {
    try {
      await patchInbox(id);
    } catch (ex) {}
  };
  handlePatch(id);
  function formatMessageDate(dateString) {
    const now = new Date();
    const messageDate = new Date(dateString);
    const elapsedTime = now - messageDate;

    const seconds = Math.floor(elapsedTime / 1000);
    const minutes = Math.floor(seconds / 60);
    const hours = Math.floor(minutes / 60);
    const days = Math.floor(hours / 24);
    const weeks = Math.floor(days / 7);

    if (seconds < 60) {
      return `${seconds} second${seconds !== 1 ? "s" : ""} ago`;
    } else if (minutes < 60) {
      return `${minutes} minute${minutes !== 1 ? "s" : ""} ago`;
    } else if (hours < 24) {
      return `${hours} hour${hours !== 1 ? "s" : ""} ago`;
    } else if (days < 7) {
      return `${days} day${days !== 1 ? "s" : ""} ago`;
    } else if (weeks < 5) {
      return `${weeks} week${weeks !== 1 ? "s" : ""} ago`;
    } else {
      const options = { year: "numeric", month: "2-digit", day: "2-digit" };
      return messageDate.toLocaleDateString(undefined, options);
    }
  }

  function stringToColor(string) {
    let hash = 0;
    let i;

    /* eslint-disable no-bitwise */
    for (i = 0; i < string.length; i += 1) {
      hash = string.charCodeAt(i) + ((hash << 5) - hash);
    }

    let color = "#";

    for (i = 0; i < 3; i += 1) {
      const value = (hash >> (i * 8)) & 0xff;
      color += `00${value.toString(16)}`.slice(-2);
    }
    /* eslint-enable no-bitwise */

    return color;
  }

  function stringAvatar(name) {
    return {
      sx: {
        bgcolor: stringToColor(name),
      },
      children: `${name.split(" ")[0][0]}${name.split(" ")[1][0]}`,
    };
  }

  const { subject, src, body, date, sender } = messages || {};
  return (
    <div className="inboxmobiledata">
      <div className="inboxsubjectheader">
        {" "}
        <Typography>{subject}</Typography>
      </div>
      <div className="inboxmobilemessagedetails">
        <div className="inboxstyleadd">
          <div
            className="inboxmobilesender"
            style={{ textTransform: "capitalize" }}
          >
            <Avatar {...stringAvatar(`${sender}`)} />
            <div className="inboxmobilesenderdetails">
              <div>{sender}</div>
              <div style={{ fontSize: "0.8rem" }}>
                {formatMessageDate(date)}
              </div>
            </div>
          </div>
          <div>
            <span className="margin1"></span>
          </div>
        </div>
        <div className="inboxmobiledate">
          <FontAwesomeIcon icon={faInbox} />
        </div>
      </div>
      <div className="inboxcompletemessage">
        <Typography variant="body1" component="div">
          <div dangerouslySetInnerHTML={{ __html: body }} />
        </Typography>
      </div>
    </div>
  );
};

export default InboxMobileData;
