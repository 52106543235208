import React, { useEffect, useState } from "react";
import GridWrapper from "../../../../common/GridWrapper/GridWrapper2";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUsersViewfinder } from "@fortawesome/free-solid-svg-icons";
import { faCalendarDays } from "@fortawesome/free-regular-svg-icons";
import { Link } from "react-router-dom";
import MiniLocation from "./MiniLocation";
import CaseTable from "./CaseTable";
import { faBitcoin } from "@fortawesome/free-brands-svg-icons";
import {
  faHeartCrack,
  faBuildingColumns,
  faCreditCard,
  faMoneyBillTransfer,
} from "@fortawesome/free-solid-svg-icons";
import CaseTableCrypto from "./CaseTableCrypto";
import {
  faFaceFrown,
  faFaceSmile,
  faFaceMeh,
} from "@fortawesome/free-regular-svg-icons";
import { useParams } from "react-router-dom";
import {
  faSquareInstagram,
  faSquareXTwitter,
  faLinkedin,
  faTiktok,
} from "@fortawesome/free-brands-svg-icons";
import { faFacebook } from "@fortawesome/free-brands-svg-icons";
import SocialInputs from "./SocialInputs";
import { getCase } from "../../../../services/cases";
import Loader4 from "../../Loaders/Loader4";

const facebookIcon = <FontAwesomeIcon color="#1877F2" icon={faFacebook} />;
const instagramIcon = (
  <FontAwesomeIcon color="#DD2A7B" icon={faSquareInstagram} />
);
const twitterIcon = <FontAwesomeIcon color="black" icon={faSquareXTwitter} />;
const linkedinIcon = <FontAwesomeIcon color="#0077B5" icon={faLinkedin} />;
const tiktokIcon = <FontAwesomeIcon color="black" icon={faTiktok} />;
const socialsize = "large";

const CaseDataMobile = () => {
  const [caseData, setCaseData] = useState(null); // Initialize as null for loading state
  const [loading, setLoading] = useState(true);
  const { id } = useParams();

  useEffect(() => {
    const fetchCaseData = async () => {
      try {
        const response = await getCase(id); // Assuming getCase returns the case directly
        setCaseData(response.data); // Set case data directly
      } catch (error) {
        console.error("Error fetching case data:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchCaseData(); // Call the async function
  }, [id]);
  if (loading) {
    return <Loader4 />;
  }

  const {
    socialmedia,
    fraudtype,
    tracktype,
    geodata,
    paymentinfo,
    status,
    datestarted,
    name,
  } = caseData || {};
  function convertDateToMMDDYY(dateString) {
    const date = new Date(dateString);

    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    const year = String(date.getFullYear()).slice(-2);
    return `${month}/${day}/${year}`;
  }
  const { facebook, twitter, instagram, tiktok, linkedin } = socialmedia || {};
  const { geoip, geoispname, geoosname, geoclassname } = geodata || {};
  const getFraudType = () => {
    if (fraudtype === "Crypto Fraud")
      return (
        <div>
          <FontAwesomeIcon color={"orange"} icon={faBitcoin} /> Cryptocurrency
          Fraud
        </div>
      );
    else if (fraudtype === "Bank Fraud")
      return (
        <div>
          <FontAwesomeIcon icon={faBuildingColumns} color={"grey"} />{" "}
          Banking/Wire Fraud
        </div>
      );
    else if (fraudtype === "Romance Fraud")
      return (
        <div>
          <FontAwesomeIcon icon={faHeartCrack} color={"red"} /> Romance Fraud
        </div>
      );
    else if (fraudtype === "Credit Fraud")
      return (
        <div>
          <FontAwesomeIcon icon={faCreditCard} color={"gold"} /> Credit Card
          Fraud
        </div>
      );
    else if (fraudtype === "Investment Fraud")
      return (
        <div>
          <FontAwesomeIcon icon={faMoneyBillTransfer} color={"green"} />{" "}
          Investment/Forex Fraud
        </div>
      );
    else return null;
  };
  const getStatus = (status) => {
    let icon, color;

    if (status === "Active") {
      icon = faFaceSmile;
      color = "green";
    } else if (status === "Pending") {
      icon = faFaceMeh; // Add the neutral face icon
      color = "black"; // You can choose a color for the "Pending" state
    } else {
      icon = faFaceFrown;
      color = "red";
    }

    return (
      <div>
        <div className="smileicon">
          <FontAwesomeIcon icon={icon} color={color} />
        </div>
        <div className="statusfont" style={{ color }}>
          {status}
        </div>
      </div>
    );
  };

  return (
    <GridWrapper>
      <div className="mobilecasesdata">
        <div
          className="casemobilebox"
          style={{
            backgroundColor: "inherit",
            borderRadius: "16px",
            boxShadow: "0 4px 20px rgba(0, 0, 0, 0.2)",
            padding: "1rem",
            width: "95%",
          }}
        >
          <div className="casemobiledate">
            <div className="casemobilemain">
              {" "}
              <span>
                <FontAwesomeIcon
                  color="rgba(0,0,0,0.4)"
                  icon={faCalendarDays}
                />
              </span>
              <span className="margin1"></span>
              Date started
            </div>
            <div className="casemobilep">
              {convertDateToMMDDYY(datestarted)}
            </div>
          </div>
          <div className="casemobiletarget">
            <div className="casemobilemain">
              {" "}
              <span>
                <FontAwesomeIcon icon={faUsersViewfinder} />
              </span>
              <span className="margin1"></span>
              Name
            </div>
            <div className="casemobilep">{name}</div>
          </div>
        </div>
        <div
          className="casemobilebody"
          style={{
            backgroundColor: "inherit",
            borderRadius: "16px",
            boxShadow: "0 4px 20px rgba(0, 0, 0, 0.2)",
            padding: "1rem",
            width: "95%",
          }}
        >
          <div className="casetypebottons">
            <div className="casemobileheader">Forensics type</div>
            <p className="casemobileheaderp">{getFraudType()}</p>
          </div>
          <div className="casebottons">
            <div>
              <Link
                className="expandbuttonmobile"
                to={`/account/edit-case/${id}`}
              >
                Add
              </Link>
            </div>
          </div>
        </div>
        <div
          className="casemobilelocation"
          style={{
            borderRadius: "16px",
            boxShadow: "0 4px 20px rgba(0, 0, 0, 0.2)",
            marginTop: "2rem",
            backgroundColor: "#fff",
            width: "95%",
          }}
        >
          <MiniLocation
            trackedip={geoip}
            osname={geoosname}
            classname={geoclassname}
            ispname={geoispname}
          />
        </div>
        <div
          className="casemobilepayments"
          style={{
            borderRadius: "16px",
            boxShadow: "0 4px 20px rgba(0, 0, 0, 0.2)",
            marginTop: "2rem",
            backgroundColor: "#fff",
            width: "95%",
          }}
        >
          <div className="casemobilepayments2">
            <div className="casemobileheader">Payment Forensics</div>
            {/* {paymentinfo && paymentinfo?.bankpayments?.length ? (
              <CaseTable paymentinfo={paymentinfo} />
            ) : null}
            {paymentinfo && paymentinfo.cryptopayments.length ? (
              <CaseTableCrypto paymentinfo={paymentinfo} />
            ) : (
              ""
            )} */}

            {paymentinfo &&
              paymentinfo.bankpayments &&
              paymentinfo.bankpayments.length > 0 && (
                <CaseTable paymentinfo={paymentinfo} />
              )}

            {paymentinfo && paymentinfo.cryptopayments && (
              <CaseTableCrypto paymentinfo={paymentinfo} />
            )}
          </div>
        </div>
        <div
          className="casemobilesociallinks"
          style={{
            borderRadius: "16px",
            boxShadow: "0 4px 20px rgba(0, 0, 0, 0.2)",
            marginTop: "2rem",
            backgroundColor: "#fff",
            width: "95%",
          }}
        >
          <div className="casemobilesociallinks2">
            <div style={{ marginBottom: "1rem" }} className="casemobileheader ">
              Social Tracks
            </div>
            {facebook &&
              facebook.map((v) => (
                <SocialInputs
                  key={v.id}
                  value={v.link}
                  socialIcon={facebookIcon}
                  size={socialsize}
                />
              ))}
            {instagram &&
              instagram.map((v) => (
                <SocialInputs
                  key={v.id}
                  value={v.link}
                  socialIcon={instagramIcon}
                  size={socialsize}
                />
              ))}
            {linkedin &&
              linkedin.map((v) => (
                <SocialInputs
                  key={v.id}
                  value={v.link}
                  socialIcon={linkedinIcon}
                  size={socialsize}
                />
              ))}
            {twitter &&
              twitter.map((v) => (
                <SocialInputs
                  key={v.id}
                  value={v.link}
                  socialIcon={twitterIcon}
                  size={socialsize}
                />
              ))}
            {tiktok &&
              tiktok.map((v) => (
                <SocialInputs
                  key={v.id}
                  value={v.link}
                  socialIcon={tiktokIcon}
                  size={socialsize}
                />
              ))}
          </div>
        </div>
        <div
          className="casemobilestatus"
          style={{
            borderRadius: "16px",
            boxShadow: "0 4px 20px rgba(0, 0, 0, 0.2)",
            marginTop: "2rem",
            backgroundColor: "#fff",
            width: "95%",
            marginBottom: "2rem",
          }}
        >
          <div className="casemobilestatusbox">
            <div className="casemobileheader">Status</div>
            <div style={{ marginTop: "0.5rem" }}>{getStatus(status)}</div>
          </div>
        </div>
      </div>
    </GridWrapper>
  );
};

export default CaseDataMobile;
