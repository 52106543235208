import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalendarDays } from "@fortawesome/free-regular-svg-icons";
import { faUsersViewfinder } from "@fortawesome/free-solid-svg-icons";

import CaseTable from "./CaseTable";
import "./styles.css";
import { Link } from "react-router-dom";
import CaseTableCrypto from "./CaseTableCrypto";

const CaseCard = ({ fraudtype, paymentinfo, date, name, id }) => {
  function convertDateToMMDDYY(dateString) {
    const date = new Date(dateString);

    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    const year = String(date.getFullYear()).slice(-2);
    return `${month}/${day}/${year}`;
  }

  return (
    <div className="casecardbox" style={{ marginTop: "1%" }}>
      <div className="caseheader" style={{ marginTop: "3%" }}>
        <div className="caseheaderbox">
          <div className="casehead">
            <span>
              <FontAwesomeIcon color="rgba(0,0,0,0.4)" icon={faCalendarDays} />
            </span>
            Date started
          </div>
          <div className="caseheaddetail"> {convertDateToMMDDYY(date)}</div>
        </div>
        <div className="caseheaderbox">
          <div className="caseheadsuspect">
            <span>
              <FontAwesomeIcon icon={faUsersViewfinder} />
            </span>
            Cypher target
          </div>
          <div className="caseheaddetail">{name}</div>
        </div>
      </div>
      <div className="casebody">
        <div className="casebodyboxes casebodybox1">
          <div className="casetypebottons">
            <article>Forensics type</article>
            <p>{fraudtype}</p>
          </div>
          <div className="casebottons">
            <div>
              <Link
                className="expandbutton caseexpand"
                to={`/account/edit-case/${id}`}
              >
                Add detail
              </Link>
            </div>

            <div className="buttonmargin"></div>
          </div>
        </div>
        <div className="casebodyboxes casebodybox2">
          <article>Active tracks</article>
          <p>
            <div className="casetable">
              {paymentinfo && paymentinfo.bankpayments && (
                <CaseTable paymentinfo={paymentinfo} />
              )}
              {paymentinfo && paymentinfo.cryptopayments && (
                <CaseTableCrypto paymentinfo={paymentinfo} />
              )}
            </div>
          </p>
        </div>
      </div>
    </div>
  );
};

export default CaseCard;
