import React, { useState } from "react";
import {
  Card,
  CardContent,
  TextField,
  Button,
  Checkbox,
  FormControlLabel,
  MenuItem,
  Select,
  InputLabel,
  FormControl,
  Typography,
  Grid,
} from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faUser,
  faEnvelope,
  faLock,
  faGlobe,
  faPhone,
} from "@fortawesome/free-solid-svg-icons";
import "../landingstyles.css";
import { countries } from "./countries"; // Import your countries here
import { registerUser } from "../../services/landing";
import { useNavigate } from "react-router-dom";
import Loader3 from "../../dashcomponents/pages/Loaders/Loader3";
import Loader2 from "../../dashcomponents/pages/Loaders/Loader2";
import { Link } from "react-router-dom";

export default function RegisterCard() {
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
    country: "",
    password: "",
    confirmPassword: "",
    terms: false,
    disclaimer: false,
  });

  const [errors, setErrors] = useState({});
  const [passwordMatch, setPasswordMatch] = useState(true);
  const [regErrors, setRegErrors] = useState({});
  const [loading, setLoading] = useState(false);

  const handleChange = (event) => {
    const { name, value, type, checked } = event.target;
    setRegErrors({});
    setFormData({
      ...formData,
      [name]: type === "checkbox" ? checked : value,
    });

    if (name === "email") {
      setErrors({
        ...errors,
        email: !validateEmail(value) ? "Invalid email address" : "",
      });
    }
  };

  const validateEmail = (email) => {
    const emailRegex = /^[^@\s]+@[^@\s]+\.[^@\s]+$/;
    return emailRegex.test(email);
  };

  const handleConfirmPasswordBlur = () => {
    setPasswordMatch(formData.password === formData.confirmPassword);
  };

  const isFormValid = () => {
    return (
      formData.firstName &&
      formData.lastName &&
      formData.email &&
      validateEmail(formData.email) &&
      formData.password &&
      formData.terms &&
      formData.disclaimer &&
      passwordMatch
    );
  };

  const doSubmit = async () => {
    setLoading(true);
    try {
      localStorage.setItem("email", formData.email);
      const response = await registerUser(formData);

      if (response.status === 200) {
        navigate("/email-verification");
      }
    } catch (ex) {
      if (ex.response && ex.response.status === 400) {
        setRegErrors({ message: ex.response.data });
      }
    } finally {
      setLoading(false);
    }
  };

  if (loading) {
    return (
      <div
        style={{
          display: "flex",
          minWidth: "100%",
          minHeight: "100vh",
          justifyContent: "center",
          alignItems: "center",
          background: "linear-gradient(135deg, #1d3557, #457b9d)",
        }}
      >
        <Loader2 />
      </div>
    );
  }

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        minHeight: "100vh",
        padding: "1rem",
        background: "linear-gradient(135deg, #1d3557, #457b9d)",
      }}
    >
      <Card
        style={{
          maxWidth: "700px",
          width: "100%",
          borderRadius: "16px",
          boxShadow: "0 4px 20px rgba(0, 0, 0, 0.2)",
          backgroundColor: "#ffffff",
        }}
      >
        <div
          style={{
            background: "linear-gradient(135deg, #1d3557, #457b9d)",
            padding: "1.5rem 1rem",
            textAlign: "center",
          }}
        >
          <Typography
            variant="h4"
            style={{
              color: "white",
              fontWeight: "bold",
              marginBottom: "0.5rem",
            }}
          >
            Create Your Account
          </Typography>
          <Typography
            variant="body2"
            style={{ color: "rgba(255, 255, 255, 0.8)" }}
          >
            Join cypher for an exclusive experience.
          </Typography>
        </div>
        <CardContent style={{ padding: "2rem" }}>
          <form noValidate autoComplete="off">
            <Grid container spacing={4}>
              {regErrors.message && (
                <Grid item xs={12}>
                  {" "}
                  {regErrors.message && (
                    <p style={{ color: "red" }}>{regErrors.message}</p>
                  )}
                </Grid>
              )}
              <Grid item xs={12} sm={6}>
                <TextField
                  label="First Name"
                  name="firstName"
                  value={formData.firstName}
                  onChange={handleChange}
                  fullWidth
                  InputProps={{
                    startAdornment: (
                      <FontAwesomeIcon
                        icon={faUser}
                        style={{ marginRight: "0.5rem" }}
                      />
                    ),
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  label="Last Name"
                  name="lastName"
                  value={formData.lastName}
                  onChange={handleChange}
                  fullWidth
                  InputProps={{
                    startAdornment: (
                      <FontAwesomeIcon
                        icon={faUser}
                        style={{ marginRight: "0.5rem" }}
                      />
                    ),
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  label="Email Address"
                  name="email"
                  value={formData.email}
                  onChange={handleChange}
                  fullWidth
                  InputProps={{
                    startAdornment: (
                      <FontAwesomeIcon
                        icon={faEnvelope}
                        style={{ marginRight: "0.5rem" }}
                      />
                    ),
                  }}
                  error={!!errors.email}
                  helperText={errors.email}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  label="Phone Number"
                  name="phone"
                  value={formData.phone}
                  onChange={handleChange}
                  fullWidth
                  InputProps={{
                    startAdornment: (
                      <FontAwesomeIcon
                        icon={faPhone}
                        style={{ marginRight: "0.5rem" }}
                      />
                    ),
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormControl fullWidth>
                  <InputLabel>Country</InputLabel>
                  <Select
                    name="country"
                    value={formData.country}
                    onChange={handleChange}
                  >
                    {countries.map((country) => (
                      <MenuItem key={country.code} value={country.name}>
                        {country.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  label="Password"
                  type="password"
                  name="password"
                  value={formData.password}
                  onChange={handleChange}
                  fullWidth
                  InputProps={{
                    startAdornment: (
                      <FontAwesomeIcon
                        icon={faLock}
                        style={{ marginRight: "0.5rem" }}
                      />
                    ),
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  label="Confirm Password"
                  type="password"
                  name="confirmPassword"
                  value={formData.confirmPassword}
                  onChange={handleChange}
                  onBlur={handleConfirmPasswordBlur}
                  fullWidth
                  InputProps={{
                    startAdornment: (
                      <FontAwesomeIcon
                        icon={faLock}
                        style={{ marginRight: "0.5rem" }}
                      />
                    ),
                  }}
                  error={!passwordMatch}
                  helperText={!passwordMatch && "Passwords do not match"}
                />
              </Grid>
              <Grid item xs={12}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={formData.terms}
                      onChange={handleChange}
                      name="terms"
                      color="primary"
                    />
                  }
                  label={
                    <Link to="/terms" style={{ textDecoration: "none" }}>
                      Agree to Terms and Conditions
                    </Link>
                  }
                />
              </Grid>
              <Grid item xs={12}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={formData.disclaimer}
                      onChange={handleChange}
                      name="disclaimer"
                      color="primary"
                    />
                  }
                  label={
                    <Link to="/disclaimer" style={{ textDecoration: "none" }}>
                      Agree to Disclaimer
                    </Link>
                  }
                />
              </Grid>

              <Grid item xs={12}>
                <Button
                  variant="contained"
                  disabled={!isFormValid()}
                  onClick={doSubmit}
                  fullWidth
                  style={{
                    background: "linear-gradient(135deg, #1d3557, #457b9d)",
                    color: "white",
                    fontWeight: "bold",
                  }}
                >
                  Register
                </Button>
              </Grid>
              <Grid
                item
                xs={12}
                style={{ textAlign: "center", marginTop: "1rem" }}
              >
                <Typography variant="body2">
                  Already have an account?{" "}
                  <Link
                    to="/login"
                    style={{ color: "#457b9d", fontWeight: "bold" }}
                  >
                    Log in here
                  </Link>
                </Typography>
              </Grid>
            </Grid>
          </form>
        </CardContent>
      </Card>
    </div>
  );
}
