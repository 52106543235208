import React, { useState } from "react";
import Box from "@mui/material/Box";
import btclogo from "../../../../images/logos/bitcoin.png";
import ethlogo from "../../../../images/logos/ethereum_eth_logo_e69b1c2368.png";
import dodgelogo from "../../../../images/logos/dogecoin_doge_logo_f18d59aae4.png";
import xrplogo from "../../../../images/logos/xrp_xrp_logo_c2ba16fdd9.png";
import bnblogo from "../../../../images/logos/bnb_bnb_logo_a2a64c3335.png";
import sollogo from "../../../../images/logos/solana-sol-logo.png";
import usdtlogo from "../../../../images/logos/tether_usdt_logo_0231fbcde8.png";
import usdclogo from "../../../../images/logos/usd_coin_usdc_logo_3e68fafa38.png";
import { toast } from "react-toastify";

import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import {
  TextField,
  MenuItem,
  Select,
  InputLabel,
  FormControl,
} from "@mui/material";
import GridWrapper from "../../../common/GridWrapper/GridWrapper";
import GridWrapper2 from "../../../common/GridWrapper/GridWrapper2";
import "./styles.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFacebook,
  faLinkedin,
  faSquareInstagram,
  faSquareXTwitter,
  faTiktok,
} from "@fortawesome/free-brands-svg-icons";
import { useMediaQuery } from "react-responsive";
import { addCases } from "../../../services/cases";
import { useNavigate } from "react-router";
import Loader2 from "../Loaders/Loader2";

const facebookIcon = <FontAwesomeIcon color="#1877F2" icon={faFacebook} />;
const instagramIcon = (
  <FontAwesomeIcon color="#DD2A7B" icon={faSquareInstagram} />
);
const twitterIcon = <FontAwesomeIcon color="black" icon={faSquareXTwitter} />;
const linkedinIcon = <FontAwesomeIcon color="#0077B5" icon={faLinkedin} />;
const tiktokIcon = <FontAwesomeIcon color="black" icon={faTiktok} />;

const fraudTypes = [
  "Cryptocurrency Fraud",
  "Romance Fraud",
  "Credit Card Fraud",
  "Bank Fraud",
];

const cryptoTypes = [
  { value: "BTC", label: "Bitcoin", logo: btclogo },
  { value: "ETH", label: "Ethereum", logo: ethlogo },
  { value: "XRP", label: "XRP", logo: xrplogo },
  { value: "USDT", label: "Tether", logo: usdtlogo },
  { value: "USDC", label: "USD Coin", logo: usdclogo },
  { value: "SOL", label: "Solana", logo: sollogo },
  { value: "DODGE", label: "Dogecoin", logo: dodgelogo },
  { value: "BNB", label: "Binance Coin", logo: bnblogo },
  { value: "Others", label: "Others", logo: null },
];

const socialMediaTypes = [
  { value: "Facebook", label: "Facebook", icon: facebookIcon },
  { value: "Twitter", label: "Twitter", icon: twitterIcon },
  { value: "LinkedIn", label: "LinkedIn", icon: linkedinIcon },
  { value: "Instagram", label: "Instagram", icon: instagramIcon },
  { value: "TikTok", label: "TikTok", icon: tiktokIcon },
  { value: "Others", label: "Others", icon: null },
];

const steps = [
  "Step 1: General Information",
  "Step 2: Social Engagement",
  "Step 3: Payment Forensics",
];

const AddCase = () => {
  const isMobile = useMediaQuery({ query: "(max-width: 768px)" });
  const [loading, setLoading] = useState(false);
  const [activeStep, setActiveStep] = useState(0);
  const [formData, setFormData] = useState({
    suspectName: "",
    fraudType: "",
    amountLost: "",
    fraudDetails: null,
    accusedEmail: "",
    accusedPhoneNumber: "",
    socialLinks: [{ type: "", link: "" }],
    websiteLink: "",
    additionalInfo: "",
    paymentType: "Bank Payment",
    payments: [{ type: "", address: "", txid: "", value: "" }],
    bankDetails: [
      { bankName: "", bankAccount: "", amountPaid: "", datePaid: "" },
    ],
  });
  const navigate = useNavigate();

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSocialChange = (index, event) => {
    const { name, value } = event.target;
    const updatedSocialLinks = formData.socialLinks.map((social, i) =>
      i === index ? { ...social, [name]: value } : social
    );
    setFormData((prevData) => ({
      ...prevData,
      socialLinks: updatedSocialLinks,
    }));
  };

  const addSocialLink = () => {
    setFormData((prevData) => ({
      ...prevData,
      socialLinks: [...prevData.socialLinks, { type: "", link: "" }],
    }));
  };

  const handlePaymentChange = (index, event) => {
    const { name, value } = event.target;
    const updatedPayments = formData.payments.map((payment, i) =>
      i === index ? { ...payment, [name]: value } : payment
    );
    setFormData((prevData) => ({
      ...prevData,
      payments: updatedPayments,
    }));
  };

  const addPayment = () => {
    setFormData((prevData) => ({
      ...prevData,
      payments: [...prevData.payments, { type: "", address: "", txid: "" }],
    }));
  };

  const addBankDetail = () => {
    setFormData((prevData) => ({
      ...prevData,
      bankDetails: [
        ...prevData.bankDetails,
        { bankName: "", bankAccount: "", amountPaid: "", datePaid: "" },
      ],
    }));
  };

  const handleNext = () => {
    setActiveStep((prevActiveStep) =>
      Math.min(prevActiveStep + 1, steps.length)
    );
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => Math.max(prevActiveStep - 1, 0));
  };

  const handleReset = () => {
    setActiveStep(0);
    setFormData({
      suspectName: "",
      fraudType: "",
      amountLost: "",
      fraudDetails: null,
      accusedEmail: "",
      accusedPhoneNumber: "",
      socialLinks: [{ type: "", link: "" }],
      websiteLink: "",
      additionalInfo: "",
      paymentType: "Bank Payment",
      payments: [{ type: "", address: "", txid: "", value: "" }],
      bankDetails: [
        { bankName: "", bankAccount: "", amountPaid: "", datePaid: "" },
      ],
    });
  };

  const handleFinish = async () => {
    if (!formData.suspectName) {
      toast.error("Suspect or case name required");
      handleReset();
      return;
    }

    try {
      setLoading(true);
      await addCases(formData);
      setLoading(false);
      handleReset();
      toast.success("Case added");
      navigate("/account/cases");
    } catch (ex) {
      console.log("something happened", ex);
    }

    // Reset or navigate as necessary after finish
  };

  if (loading) {
    return <Loader2 />;
  }

  const lol = (
    <div>
      Name (suspect's name or UID) <sup>*</sup>
    </div>
  );

  const renderStepContent = (step) => {
    switch (step) {
      case 0:
        return (
          <Box>
            <Typography variant="h6">Step 1: Case Details</Typography>
            <TextField
              label={lol}
              name="suspectName"
              value={formData.suspectName}
              onChange={handleChange}
              fullWidth
              margin="normal"
            />
            <TextField
              label="Fraud Type"
              name="fraudType"
              select
              value={formData.fraudType}
              onChange={handleChange}
              fullWidth
              margin="normal"
            >
              {fraudTypes.map((type) => (
                <MenuItem key={type} value={type}>
                  {type}
                </MenuItem>
              ))}
            </TextField>
            <TextField
              label="Amount Lost (in USD)"
              name="amountLost"
              value={formData.amountLost}
              onChange={handleChange}
              type="number"
              fullWidth
              margin="normal"
            />
            <TextField
              label=""
              name="fraudDetails"
              type="file"
              onChange={handleChange}
              fullWidth
              margin="normal"
            />
          </Box>
        );
      case 1:
        return (
          <Box>
            <Typography variant="h6">Step 2: Social Information</Typography>
            <TextField
              label="Suspect's Email (optional)"
              name="accusedEmail"
              value={formData.accusedEmail}
              onChange={handleChange}
              fullWidth
              margin="normal"
              type="email"
            />
            <TextField
              type="tel"
              label="Suspect's Phone Number (optional)"
              name="accusedPhoneNumber"
              value={formData.accusedPhoneNumber}
              onChange={handleChange}
              fullWidth
              margin="normal"
            />
            <TextField
              label="Website Link (optional)"
              name="websiteLink"
              value={formData.websiteLink}
              onChange={handleChange}
              fullWidth
              margin="normal"
            />
            <TextField
              label="Additional Info"
              name="additionalInfo"
              value={formData.additionalInfo}
              onChange={handleChange}
              fullWidth
              margin="normal"
            />
            <Typography variant="h6" sx={{ mt: 2 }}>
              Social Links
            </Typography>
            {formData.socialLinks.map((social, index) => (
              <Box key={index} sx={{ mb: 2 }}>
                <FormControl fullWidth margin="normal">
                  <InputLabel>Type</InputLabel>
                  <Select
                    name="type"
                    value={social.type}
                    onChange={(e) => handleSocialChange(index, e)}
                  >
                    {socialMediaTypes.map((media) => (
                      <MenuItem key={media.value} value={media.value}>
                        {media.icon} <span className="margin1"></span>
                        {media.label}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                <TextField
                  label="Social Link"
                  name="link"
                  value={social.link}
                  onChange={(e) => handleSocialChange(index, e)}
                  fullWidth
                  margin="normal"
                />
              </Box>
            ))}
            <Button variant="contained" onClick={addSocialLink}>
              + Add Another Social Link
            </Button>
          </Box>
        );
      case 2:
        return (
          <Box>
            <Typography variant="h6">Step 3: Payment Details</Typography>
            <TextField
              label="Payment Type (Bank or Cryptocurrency)"
              select
              name="paymentType"
              value={formData.paymentType}
              onChange={handleChange}
              fullWidth
              margin="normal"
            >
              <MenuItem value="Bank Payment">Bank Payment</MenuItem>
              <MenuItem value="Crypto Payment">Crypto Payment</MenuItem>
            </TextField>

            {/* Render Crypto Payments */}
            {formData.paymentType === "Crypto Payment" && (
              <>
                {formData.payments.map((payment, index) => (
                  <Box key={index} sx={{ mb: 2 }}>
                    <FormControl fullWidth margin="normal">
                      <InputLabel>Type</InputLabel>
                      <Select
                        name="type"
                        value={payment.type}
                        onChange={(e) => handlePaymentChange(index, e)}
                      >
                        {cryptoTypes.map((crypto) => (
                          <MenuItem key={crypto.value} value={crypto.value}>
                            <img
                              src={crypto.logo}
                              alt={crypto.label}
                              style={{ width: "24px", marginRight: "8px" }}
                            />
                            {crypto.label}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                    <TextField
                      label="Wallet Address"
                      name="address"
                      value={payment.address}
                      onChange={(e) => handlePaymentChange(index, e)}
                      fullWidth
                      margin="normal"
                    />
                    <TextField
                      label="Transaction ID"
                      name="txid"
                      value={payment.txid}
                      onChange={(e) => handlePaymentChange(index, e)}
                      fullWidth
                      margin="normal"
                    />
                    <TextField
                      label="Amount Lost"
                      name="value"
                      value={payment.value}
                      onChange={(e) => handlePaymentChange(index, e)}
                      fullWidth
                      margin="normal"
                    />
                  </Box>
                ))}
                <Button variant="contained" onClick={addPayment}>
                  + Add Another Crypto Payment
                </Button>
              </>
            )}

            {/* Render Bank Payments */}
            {formData.paymentType === "Bank Payment" && (
              <>
                {formData.bankDetails.map((bankDetail, index) => (
                  <Box key={index} sx={{ mb: 2 }}>
                    <TextField
                      label="Bank Name"
                      name="bankName"
                      value={bankDetail.bankName}
                      onChange={(e) => {
                        const updatedBankDetails = formData.bankDetails.map(
                          (detail, i) =>
                            i === index
                              ? { ...detail, bankName: e.target.value }
                              : detail
                        );
                        setFormData((prevData) => ({
                          ...prevData,
                          bankDetails: updatedBankDetails,
                        }));
                      }}
                      fullWidth
                      margin="normal"
                    />
                    <TextField
                      label="Bank Account"
                      name="bankAccount"
                      value={bankDetail.bankAccount}
                      onChange={(e) => {
                        const updatedBankDetails = formData.bankDetails.map(
                          (detail, i) =>
                            i === index
                              ? { ...detail, bankAccount: e.target.value }
                              : detail
                        );
                        setFormData((prevData) => ({
                          ...prevData,
                          bankDetails: updatedBankDetails,
                        }));
                      }}
                      fullWidth
                      margin="normal"
                    />
                    <TextField
                      label="Amount Paid"
                      name="amountPaid"
                      value={bankDetail.amountPaid}
                      onChange={(e) => {
                        const updatedBankDetails = formData.bankDetails.map(
                          (detail, i) =>
                            i === index
                              ? { ...detail, amountPaid: e.target.value }
                              : detail
                        );
                        setFormData((prevData) => ({
                          ...prevData,
                          bankDetails: updatedBankDetails,
                        }));
                      }}
                      type="number"
                      fullWidth
                      margin="normal"
                    />
                    <TextField
                      label="Date Paid"
                      name="datePaid"
                      value={bankDetail.datePaid}
                      onChange={(e) => {
                        const updatedBankDetails = formData.bankDetails.map(
                          (detail, i) =>
                            i === index
                              ? { ...detail, datePaid: e.target.value }
                              : detail
                        );
                        setFormData((prevData) => ({
                          ...prevData,
                          bankDetails: updatedBankDetails,
                        }));
                      }}
                      type="date"
                      fullWidth
                      margin="normal"
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  </Box>
                ))}
                <Button variant="contained" onClick={addBankDetail}>
                  + Add Another Bank Payment
                </Button>
              </>
            )}
          </Box>
        );
      default:
        return null;
    }
  };

  if (isMobile) {
    return (
      <GridWrapper2>
        <Box
          sx={{
            padding: 4,
            backgroundColor: "white",
            borderRadius: 2,
            boxShadow: 3,
          }}
        >
          <Stepper activeStep={activeStep}>
            {steps.map((label) => (
              <Step key={label}>
                <StepLabel>{label}</StepLabel>
              </Step>
            ))}
          </Stepper>
          {activeStep === steps.length ? (
            <React.Fragment>
              <Typography sx={{ mt: 2, mb: 1 }}>
                All steps have been completed. Please confirm to proceed with
                adding a new case. Ensure all required information is provided
                to maximize efficiency and enhance P<sub>k</sub>​ .
              </Typography>
              <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
                <Box sx={{ flex: "1 1 auto" }} />
                <Button onClick={handleFinish} variant="contained">
                  Confirm
                </Button>
                <Button onClick={handleReset}>Reset</Button>
              </Box>
            </React.Fragment>
          ) : (
            <React.Fragment>
              {renderStepContent(activeStep)}
              <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
                <Button
                  color="inherit"
                  disabled={activeStep === 0}
                  onClick={handleBack}
                  sx={{ mr: 1 }}
                >
                  Back
                </Button>
                <Box sx={{ flex: "1 1 auto" }} />
                <Button onClick={handleNext}>
                  {activeStep === steps.length - 1 ? "Finish" : "Next"}
                </Button>
              </Box>
            </React.Fragment>
          )}
        </Box>
      </GridWrapper2>
    );
  }

  return (
    <GridWrapper>
      <Box
        sx={{
          padding: 4,
          backgroundColor: "white",
          borderRadius: 2,
          boxShadow: 3,
        }}
      >
        <Stepper activeStep={activeStep}>
          {steps.map((label) => (
            <Step key={label}>
              <StepLabel>{label}</StepLabel>
            </Step>
          ))}
        </Stepper>
        {activeStep === steps.length ? (
          <React.Fragment>
            <Typography sx={{ mt: 2, mb: 1 }}>
              All steps have been completed. Please confirm to proceed with
              adding a new case. Ensure all required information is provided to
              maximize efficiency and enhance P<sub>k</sub>​ .
            </Typography>
            <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
              <Box sx={{ flex: "1 1 auto" }} />
              <Button onClick={handleFinish} variant="contained">
                Confirm
              </Button>
              <Button onClick={handleReset}>Reset</Button>
            </Box>
          </React.Fragment>
        ) : (
          <React.Fragment>
            {renderStepContent(activeStep)}
            <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
              <Button
                color="inherit"
                disabled={activeStep === 0}
                onClick={handleBack}
                sx={{ mr: 1 }}
              >
                Back
              </Button>
              <Box sx={{ flex: "1 1 auto" }} />
              <Button onClick={handleNext}>
                {activeStep === steps.length - 1 ? "Finish" : "Next"}
              </Button>
            </Box>
          </React.Fragment>
        )}
      </Box>
    </GridWrapper>
  );
};

export default AddCase;
