import React from "react";

const AuthEmail = () => {
  return (
    <div className="mainlandingpage">
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          height: "100vh",
          padding: "20px",
          background: "linear-gradient(135deg, #1d3557, #457b9d)",
          color: "#ffffff",
          fontFamily: "'Poppins', sans-serif",
          textAlign: "center",
        }}
      >
        <div
          style={{
            background: "#ffffff",
            borderRadius: "16px",
            padding: "40px",
            maxWidth: "500px",
            boxShadow: "0 10px 30px rgba(0, 0, 0, 0.2)",
          }}
        >
          <h1
            style={{
              fontSize: "24px",
              fontWeight: "bold",
              marginBottom: "10px",
              color: "#1d3557",
            }}
          >
            Email Sent!
          </h1>
          <p
            style={{
              fontSize: "16px",
              color: "#555555",
              marginBottom: "20px",
            }}
          >
            If an account with this email exists, a password reset email will be
            sent.
          </p>
        </div>
        <div
          style={{
            marginTop: "30px",
            animation: "fadeInUp 1.2s ease-out",
          }}
        >
          <p style={{ fontSize: "14px", color: "#ffffff" }}>
            Didn’t receive the email? Make sure to check your spam or junk
            folder.
          </p>
        </div>
        <style>
          {`
          @keyframes fadeInUp {
            from {
              opacity: 0;
              transform: translateY(20px);
            }
            to {
              opacity: 1;
              transform: translateY(0);
            }
          }
        `}
        </style>
      </div>
    </div>
  );
};
export default AuthEmail;
