import React, { useEffect, useRef, useState } from "react";
import "./recoveryBar.css";

const RecoveryBar = () => {
  const [fillPercentage, setFillPercentage] = useState(0);
  const ref = useRef();

  useEffect(() => {
    const handleIntersection = (entries) => {
      const [entry] = entries;
      if (entry.isIntersecting) {
        // Trigger the animation when the element comes into view
        const timeout1 = setTimeout(() => setFillPercentage(100), 500);
        const timeout2 = setTimeout(() => setFillPercentage(87), 3000);
        return () => {
          clearTimeout(timeout1);
          clearTimeout(timeout2);
        };
      } else {
        // Reset the fill percentage when out of view
        setFillPercentage(0);
      }
    };

    const observer = new IntersectionObserver(handleIntersection, {
      threshold: 0.5, // Trigger when 50% of the element is visible
    });

    if (ref.current) {
      observer.observe(ref.current);
    }

    return () => {
      if (ref.current) {
        observer.unobserve(ref.current);
      }
    };
  }, []);

  return (
    <div className="recovery-bar-container">
      <div className="recovery-bar" ref={ref}>
        <div
          className="recovery-bar-fill"
          style={{ width: `${fillPercentage}%` }}
        />
      </div>
      <div className="recovery-bar-label">
        Recovery Ratio: <span>{fillPercentage}%</span>
      </div>
    </div>
  );
};

export default RecoveryBar;
