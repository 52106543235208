import React from "react";
import GridWrapper from "../../../common/GridWrapper/GridWrapper";
import GridWrapper2 from "../../../common/GridWrapper/GridWrapper2";
import AnalyticsMain from "./AnalyticsComponents/AnalyticsMain";
import { getCases } from "../../../services/cases";
import { useState, useEffect } from "react";
import { Typography } from "@mui/material";
import Loader2 from "../Loaders/Loader2";
import { useMediaQuery } from "react-responsive";

const Analytics = () => {
  const isMobile = useMediaQuery({ query: "(max-width: 768px)" });
  const [caselist, setCaselist] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  useEffect(() => {
    const fetchCases = async () => {
      try {
        const response = await getCases();
        const newdata = response.data;
        setCaselist(newdata);
      } catch (err) {
        setError("Failed to fetch cases.");
        console.error(err);
      } finally {
        setLoading(false);
      }
    };

    fetchCases(); // Call the async function
  }, []);

  if (loading) {
    return <Loader2 />;
  }

  if (caselist.length == 0) {
    return (
      <Typography
        align="center"
        sx={{
          margin: "40px 16px",
          color: "rgba(0, 0, 0, 0.6)",
          fontSize: "1.3rem",
        }}
      >
        No active cases
      </Typography>
    );
  }

  if (isMobile) {
    return (
      <GridWrapper2>
        <AnalyticsMain caselist={caselist} />
      </GridWrapper2>
    );
  }
  return (
    <GridWrapper>
      <AnalyticsMain caselist={caselist} />
    </GridWrapper>
  );
};

export default Analytics;
