export const gridWrapperStyles = {
  position: "relative",
  padding: "3rem 0.1rem 0.3rem 0.1rem",
  minHeight: "calc(100vh - 1px)",

  minWidth: "100%",
  marginTop: "0.5rem",
  marginLeft: "0rem",
  display: "flex",
  flexDirection: "column",

  alignItems: "center",
};
