import React, { useEffect, useState } from "react";
import GridWrapper from "../../../common/GridWrapper/GridWrapper";
import HelpDeskMain from "./HelpDeskMainComponents/HelpDeskMain";
import GridWrapper2 from "../../../common/GridWrapper/GridWrapper2";
import { useMediaQuery } from "react-responsive";
import { getArticle } from "../../../services/updates";
import Loader2 from "../Loaders/Loader2";

const HelpDesk = () => {
  const isMobile = useMediaQuery({ query: "(max-width: 768px)" });
  const [loading, setLoading] = useState(true);
  const [articles, setArticles] = useState([]);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchArticles = async () => {
      try {
        const response = await getArticle();
        const newdata = response.data;
        setArticles(newdata);
      } catch (err) {
        setError("Failed to fetch Articles.");
        console.error(err);
      } finally {
        setLoading(false);
      }
    };

    fetchArticles(); // Call the async function
  }, []);

  if (loading) {
    return <Loader2 />;
  }

  if (isMobile) {
    return (
      <GridWrapper2>
        <HelpDeskMain articles={articles} />
      </GridWrapper2>
    );
  }
  return (
    <GridWrapper>
      <HelpDeskMain articles={articles} />
    </GridWrapper>
  );
};

export default HelpDesk;
