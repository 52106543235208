import React from "react";
import "./styles.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFaceFrown,
  faFaceSmile,
  faFaceMeh,
} from "@fortawesome/free-regular-svg-icons";
import { faFacebook } from "@fortawesome/free-brands-svg-icons";
import SocialInputs from "./SocialInputs";
import {
  faSquareInstagram,
  faSquareXTwitter,
  faLinkedin,
  faTiktok,
} from "@fortawesome/free-brands-svg-icons";
import { faCircle } from "@fortawesome/free-solid-svg-icons";
import MiniLocation from "./MiniLocation";

const CaseDataSide = ({
  status,
  fraudtype,
  socialmedia,
  tracktype,
  trackinfo,
}) => {
  let facebook, twitter, instagram, tiktok, linkedin;
  if (socialmedia) {
    ({ facebook, twitter, instagram, tiktok, linkedin } = socialmedia);
  }

  let geoip, geoispname, geoosname, geoclassname;
  if (trackinfo) {
    ({ geoip, geoispname, geoosname, geoclassname } = trackinfo);
  }

  const {
    paymentforensics,
    socialengineering,
    backgroundcheck,
    cypherrecovery,
  } = tracktype || {};

  const facebookIcon = <FontAwesomeIcon color="#1877F2" icon={faFacebook} />;
  const instagramIcon = (
    <FontAwesomeIcon color="#DD2A7B" icon={faSquareInstagram} />
  );
  const twitterIcon = <FontAwesomeIcon color="black" icon={faSquareXTwitter} />;
  const linkedinIcon = <FontAwesomeIcon color="#0077B5" icon={faLinkedin} />;
  const tiktokIcon = <FontAwesomeIcon color="black" icon={faTiktok} />;
  const socialsize = "small";

  const getStatus = (status) => {
    let icon, color;

    if (status === "Active") {
      icon = faFaceSmile;
      color = "green";
    } else if (status === "Pending") {
      icon = faFaceMeh; // Add the neutral face icon
      color = "black"; // You can choose a color for the "Pending" state
    } else {
      icon = faFaceFrown;
      color = "red";
    }

    return (
      <div>
        <div className="smileicon">
          <FontAwesomeIcon icon={icon} color={color} />
        </div>
        <div className="statusfont" style={{ color }}>
          {status}
        </div>
      </div>
    );
  };

  return (
    <div className="sidelist casecardbox " style={{ marginTop: "2%" }}>
      <ul style={{ width: "90%", marginTop: "7%" }}>
        <li>
          <div className="sidelisthead">Status</div>
          <div className="sidelistdetail">{getStatus(status)}</div>
        </li>
        <li>
          <div className="sidelisthead">Social links</div>
          <div className="sidelisttarget">
            {facebook &&
              facebook.map((v) => (
                <SocialInputs
                  key={v.id}
                  value={v.link}
                  socialIcon={facebookIcon}
                  size={socialsize}
                />
              ))}
            {instagram &&
              instagram.map((v) => (
                <SocialInputs
                  key={v.id}
                  value={v.link}
                  socialIcon={instagramIcon}
                  size={socialsize}
                />
              ))}
            {linkedin &&
              linkedin.map((v) => (
                <SocialInputs
                  key={v.id}
                  value={v.link}
                  socialIcon={linkedinIcon}
                  size={socialsize}
                />
              ))}
            {twitter &&
              twitter.map((v) => (
                <SocialInputs
                  key={v.id}
                  value={v.link}
                  socialIcon={twitterIcon}
                  size={socialsize}
                />
              ))}
            {tiktok &&
              tiktok.map((v) => (
                <SocialInputs
                  key={v.id}
                  value={v.link}
                  socialIcon={tiktokIcon}
                  size={socialsize}
                />
              ))}
          </div>
        </li>
        <li>
          <div className="sidelisthead">Fraud Type</div>
          <div className="sidelistdetail fraudtype">{fraudtype}</div>
        </li>
        <li>
          <div className="sidelisthead">Track type</div>
          <div className="sidelistdetail">
            <div className="tracktypebox">
              {backgroundcheck && (
                <div className="tracktype">
                  <FontAwesomeIcon icon={faCircle} size="2xs" color="green" />
                  <span> </span>
                  <span>Background checks</span>
                </div>
              )}
              {socialengineering && (
                <div className="tracktype">
                  <FontAwesomeIcon icon={faCircle} size="2xs" color="green" />
                  <span> </span>
                  <span>Social engineering</span>
                </div>
              )}
              {paymentforensics && (
                <div className="tracktype">
                  <FontAwesomeIcon icon={faCircle} size="2xs" color="green" />
                  <span> </span>
                  <span>Payment and account analysis</span>
                </div>
              )}
              {cypherrecovery && (
                <div className="tracktype">
                  <FontAwesomeIcon icon={faCircle} size="2xs" color="green" />
                  <span> </span>
                  <span>Cypher recovery</span>
                </div>
              )}
            </div>
          </div>
        </li>
        <li>
          <div className="sidelisthead">Location & IP</div>
          <div className="sidelistdetail" style={{ height: "12rem" }}>
            <MiniLocation
              trackedip={geoip}
              osname={geoosname}
              classname={geoclassname}
              ispname={geoispname}
            />
          </div>
        </li>
      </ul>

      <div style={{ minHeight: "5%" }}> </div>
    </div>
  );
};

export default CaseDataSide;
