import {
  faFacebook,
  faLinkedin,
  faSquareInstagram,
  faSquareXTwitter,
  faTiktok,
} from "@fortawesome/free-brands-svg-icons";
import { faCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { useMediaQuery } from "react-responsive";
import { Link } from "react-router-dom";

const AnalyticsSocial = ({
  socialmedia,
  className,
  ip,
  coordinates,
  mapdata,
}) => {
  const isMobile = useMediaQuery({ query: "(max-width: 768px)" });
  const mobStyle = {
    borderRadius: "16px",
    boxShadow: "0 4px 20px rgba(0, 0, 0, 0.2)",
    backgroundColor: "inherit",
    width: "90%",
    marginTop: "3rem",
  };
  const { facebook, tiktok, instagram, linkedin, twitter, email } = socialmedia;
  return (
    <div className="analyticssocial" style={isMobile ? mobStyle : null}>
      <header>Social Engagement</header>
      <div className="analyticssocialbox">
        <h5>
          Social Constructs <span className="margin1"></span>
          <span className="margin1"></span>
          <span className="margin1"></span>
          <FontAwesomeIcon color={"green"} icon={faCircle} />
        </h5>
        <article>Social Links Engaged</article>

        {facebook.length ? (
          <p>
            <FontAwesomeIcon color={"blue"} icon={faFacebook} />
            <span className="margin1"></span>Facebook
          </p>
        ) : (
          ""
        )}
        {instagram.length ? (
          <p>
            <FontAwesomeIcon color="#DD2A7B" icon={faSquareInstagram} />{" "}
            <span className="margin1"></span>
            Instagram
          </p>
        ) : (
          ""
        )}

        {twitter.length ? (
          <p>
            <FontAwesomeIcon color="black" icon={faSquareXTwitter} />{" "}
            <span className="margin1"></span>
            Twitter
          </p>
        ) : (
          ""
        )}

        {linkedin.length ? (
          <p>
            <FontAwesomeIcon color="#0077B5" icon={faLinkedin} />{" "}
            <span className="margin1"></span>
            Linkedin
          </p>
        ) : (
          ""
        )}

        {tiktok.length ? (
          <p>
            <FontAwesomeIcon color="black" icon={faTiktok} />{" "}
            <span className="margin1"></span>Tiktok
          </p>
        ) : (
          ""
        )}

        <article>Emails</article>
        {email.map((e, index) => <p key={index}>{e}</p>) || "NA"}
        <h5>
          IP Trace <span className="margin1"></span>
          <span className="margin1"></span>
          <span className="margin1"></span>{" "}
          <FontAwesomeIcon color={"green"} icon={faCircle} />
        </h5>
        <article>Trace Data</article>
        <p>
          {ip}
          <span className="margin1">
            <span className={className}></span>
          </span>
        </p>
        <p>
          <Link>{coordinates}</Link>
        </p>
        <article>Geo-tag</article>
        <p className="analyticsgeotagp">
          <Link className="analyticsgeotaglink" to={mapdata}>
            <div className="analyticsgeotagplus">{mapdata} </div>
          </Link>
        </p>
      </div>
    </div>
  );
};

export default AnalyticsSocial;
