import HomeIcon from "@mui/icons-material/Home";
import MailIcon from "@mui/icons-material/Mail";
import WorkIcon from "@mui/icons-material/Work";
import InsightsIcon from "@mui/icons-material/Insights";
import AnalyticsIcon from "@mui/icons-material/Analytics";
import InfoIcon from "@mui/icons-material/Info";
import Badge from "@mui/material/Badge";

export const mainNavBarItems = [
  {
    id: 0,
    icon: <HomeIcon />,
    label: "Home",
    route: "home",
    helper: "Add and manage cases securly with just a click",
  },
  {
    id: 1,
    icon: (
      <Badge badgeContent={0} color="primary">
        <MailIcon />
      </Badge>
    ),
    label: "Inbox",
    route: "inbox",
    helper: "Get real-time updates on active cases and recovery efforts",
  },
  {
    id: 2,
    icon: <WorkIcon />,
    label: "Cases",
    route: "cases",
    helper:
      "Manage and edit cases. Add more information about your case to improve pk score",
  },
  {
    id: 3,
    icon: <InsightsIcon />,
    label: "Status",
    route: "status",
    helper: "View real-time data on info collected regarding your case",
  },
  {
    id: 4,
    icon: <AnalyticsIcon />,
    label: "Analytics",
    route: "analytics",
    helper: "Get additional insight regarding your active and pending cases",
  },
  {
    id: 5,
    icon: <InfoIcon />,
    label: "Scam Info",
    route: "scaminfo",
    helper: "View updates about trending and latest scam activities",
  },
];
