import React, { useEffect, useState } from "react";
import {
  Box,
  Typography,
  Card,
  CardContent,
  Grid,
  Button,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import { useNavigate } from "react-router";
import { getUserMe } from "../../../services/user";
import EmailIcon from "@mui/icons-material/Email";
import PhoneIcon from "@mui/icons-material/Phone";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import VerifiedUserIcon from "@mui/icons-material/VerifiedUser";
import WorkIcon from "@mui/icons-material/Work";

const ProfilePage = () => {
  const [user, setUser] = useState({});
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchUser = async () => {
      try {
        const response = await getUserMe();
        const newdata = response.data;
        setUser(newdata);
      } catch (err) {
        setError("Failed to fetch User.");
        console.error(err);
      } finally {
        setLoading(false);
      }
    };

    fetchUser();
  }, []);

  const defaultMessages = {
    firstName: "First name not available",
    lastName: "Last name not available",
    phone: "Phone number not available",
    country: "Country not provided",
  };

  const userDetails = [
    {
      label: "First Name",
      value: user.firstName || defaultMessages.firstName,
      icon: <EditIcon />,
    },
    {
      label: "Last Name",
      value: user.lastName || defaultMessages.lastName,
      icon: <EditIcon />,
    },
    { label: "Email", value: user.email, icon: <EmailIcon /> },
    {
      label: "Phone",
      value: user.phone || defaultMessages.phone,
      icon: <PhoneIcon />,
    },
    {
      label: "Country",
      value: user.country || defaultMessages.country,
      icon: <LocationOnIcon />,
    },
    {
      label: "Account Type",
      value: user.isFirm ? "Firm" : "Individual",
      icon: <WorkIcon />,
    },
    {
      label: "Verification Status",
      value: user.isVerified ? "Verified" : "Not Verified",
      icon: <VerifiedUserIcon />,
    },
  ];

  return (
    <Box
      sx={{
        minHeight: "100vh",
        backgroundColor: "#f8f9fa",
        py: 5,
        px: { xs: 2, md: 10 },
      }}
    >
      <Card
        sx={{
          maxWidth: 900,
          margin: "0 auto",
          backgroundColor: "#ffffff",
          boxShadow: "0px 6px 30px rgba(0, 0, 0, 0.1)",
          borderRadius: "16px",
          overflow: "hidden",
        }}
      >
        <CardContent>
          <Typography
            variant="h4"
            gutterBottom
            sx={{
              textAlign: "center",
              fontWeight: "bold",
              color: "#333",
            }}
          >
            User Profile
          </Typography>

          <Box sx={{ textAlign: "center", marginBottom: 3 }}>
            <Typography variant="subtitle1" sx={{ color: "#555" }}>
              Welcome, {user.firstName || "User"}!
            </Typography>
          </Box>

          <Grid container spacing={3}>
            {userDetails.map((detail, index) => (
              <Grid item xs={12} sm={6} md={4} key={index}>
                <Box
                  sx={{
                    backgroundColor: "#f3f4f6",
                    padding: 3,
                    borderRadius: "10px",
                    boxShadow: "0px 2px 10px rgba(0, 0, 0, 0.05)",
                    display: "flex",
                    alignItems: "center",
                    gap: 2,
                  }}
                >
                  {detail.icon}
                  <Box>
                    <Typography variant="subtitle2" sx={{ color: "#555" }}>
                      {detail.label}
                    </Typography>
                    <Typography
                      variant="body1"
                      sx={{ fontWeight: "500", color: "#333" }}
                    >
                      {detail.value}
                    </Typography>
                  </Box>
                </Box>
              </Grid>
            ))}
          </Grid>

          <Box sx={{ textAlign: "center", marginTop: 4 }}>
            <Button
              variant="contained"
              startIcon={<EditIcon />}
              onClick={() => navigate(`/account/change-password`)}
              sx={{
                backgroundColor: "#007bff",
                color: "#fff",
                borderRadius: "30px",
                padding: "12px 30px",
                textTransform: "none",
                "&:hover": {
                  backgroundColor: "#0056b3",
                },
                transition: "background-color 0.3s ease",
              }}
            >
              Change Password
            </Button>
          </Box>

          {user?.isAdmin && (
            <Box sx={{ textAlign: "center", marginTop: 4 }}>
              <Button
                variant="contained"
                startIcon={<EditIcon />}
                onClick={() => navigate("/admin")}
                sx={{
                  backgroundColor: "#007bff",
                  color: "#fff",
                  borderRadius: "30px",
                  padding: "12px 30px",
                  textTransform: "none",
                  "&:hover": {
                    backgroundColor: "#0056b3",
                  },
                  transition: "background-color 0.3s ease",
                }}
              >
                Admin Hub
              </Button>
            </Box>
          )}

          {user?.isAdmin && (
            <Box sx={{ textAlign: "center", marginTop: 4 }}>
              <Button
                variant="contained"
                startIcon={<EditIcon />}
                onClick={() => navigate("/admin/news")}
                sx={{
                  backgroundColor: "#007bff",
                  color: "#fff",
                  borderRadius: "30px",
                  padding: "12px 30px",
                  textTransform: "none",
                  "&:hover": {
                    backgroundColor: "#0056b3",
                  },
                  transition: "background-color 0.3s ease",
                }}
              >
                Post News
              </Button>
            </Box>
          )}

          {user?.isMaster && (
            <Box sx={{ textAlign: "center", marginTop: 4 }}>
              <Button
                variant="contained"
                startIcon={<EditIcon />}
                onClick={() => navigate("/master")}
                sx={{
                  backgroundColor: "#007bff",
                  color: "#fff",
                  borderRadius: "30px",
                  padding: "12px 30px",
                  textTransform: "none",
                  "&:hover": {
                    backgroundColor: "#0056b3",
                  },
                  transition: "background-color 0.3s ease",
                }}
              >
                Master Hub
              </Button>
            </Box>
          )}
        </CardContent>
      </Card>
    </Box>
  );
};

export default ProfilePage;
