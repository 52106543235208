import axios from "axios";
const apiUrl = process.env.REACT_APP_API_URL;

export function getTraceData() {
  const token = localStorage.getItem("token");
  return axios.get(`${apiUrl}/api/traces`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
}
