import React, { useState } from "react";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import "./styles.css";
import StatusLocation from "./StatusLocation";
import StatusInfo from "./StatusInfo";
import StatusPayment from "./StatusPayment";
import StatusTarget from "./StatusTarget";
import { Typography } from "@mui/material";

const MainStatus = ({ caselist }) => {
  const [selectedValue, setSelectedValue] = useState("");
  const [selectedCase, setSelectedCase] = useState(null);

  const handleChange = (event) => {
    const caseValue = event.target.value;
    setSelectedValue(caseValue);

    const caseData = caselist.find((c) => c.value === caseValue);
    setSelectedCase(caseData);
  };

  return (
    <div className="mainstatusbox">
      <div className="statusheader">
        <FormControl fullWidth>
          <InputLabel id="demo-simple-select-label">Select Case</InputLabel>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={selectedValue}
            label="Select Case"
            onChange={handleChange}
            placeholder="Select Case"
          >
            {caselist &&
              caselist.map((c) => (
                <MenuItem key={c.id} value={c.value}>
                  {c.name}
                </MenuItem>
              ))}
          </Select>
        </FormControl>
      </div>

      {!selectedCase && (
        <div
          style={{
            boxShadow: "0 4px 20px rgba(0, 0, 0, 0.2)",

            backgroundColor: "inherit",

            minHeight: "10rem",
            width: "90%",
            marginTop: "10%",
            justifyContent: "center",
            alignItems: "center",
            display: "flex",
          }}
        >
          <div>
            <Typography variant="body1">
              Select a case to get started
            </Typography>
          </div>
        </div>
      )}
      <div>
        {caselist && selectedCase && (
          <StatusLocation
            trackinfo
            trackedip={selectedCase.trackinfo.trackedip}
            ispname={selectedCase.trackinfo.ispname}
            osname={selectedCase.trackinfo.osname}
            coordinates={selectedCase.trackinfo.coordinates}
            mapdata={selectedCase.trackinfo.mapdata}
            geoip={selectedCase.geodata.geoip}
            geoispname={selectedCase.geodata.geoispname}
            geocoordinates={selectedCase.geodata.geocoordinates}
            geomapdata={selectedCase.geodata.geomapdata}
            geoosname={selectedCase.geodata.geoosname}
            classname={selectedCase.trackinfo.classname}
            geoclassname={selectedCase.geodata.geoclassname}
            location={selectedCase.trackinfo.location}
            geolocation={selectedCase.geodata.geolocation}
          />
        )}
      </div>
      <div>{selectedCase && <StatusInfo />}</div>
      <div>
        {selectedCase && (
          <StatusTarget
            fraudtype={selectedCase.fraudtype}
            socialmedia={selectedCase.socialmedia}
            target={selectedCase.name}
          />
        )}
      </div>
      <div>
        {selectedCase && (
          <StatusPayment
            active={selectedCase.status}
            assetworth={selectedCase.value}
            bill={selectedCase.bill}
            stage={selectedCase.stage}
          />
        )}{" "}
      </div>
    </div>
  );
};

export default MainStatus;
