import React from "react";
import "./styles.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faHandcuffs,
  faLocationPinLock,
  faMagnifyingGlassLocation,
  faMoneyBillTransfer,
  faUsersRays,
} from "@fortawesome/free-solid-svg-icons";
import CircularProgress from "@mui/material/CircularProgress";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import { styled } from "@mui/material/styles";
import { useMediaQuery } from "react-responsive";

const StatusInfo = () => {
  const isMobile = useMediaQuery({ query: "(max-width: 768px)" });
  const NoMaxWidthTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ))({
    [`& .${tooltipClasses.tooltip}`]: {
      maxWidth: "none",
    },
  });
  const picon = "1.6rem";

  const mobStyle = {
    borderRadius: "16px",
    boxShadow: "0 4px 20px rgba(0, 0, 0, 0.2)",
    marginTop: "3rem",
  };

  return (
    <div
      className={isMobile ? "" : "statusinfo"}
      style={isMobile ? mobStyle : null}
    >
      <div className="statusinfobox">
        <NoMaxWidthTooltip title="The use of forensic meta-data obtained from payment chain analysis, social engineering constructs and ip analysis to construct a target model ">
          <div className="statusdetail">
            <article>
              <FontAwesomeIcon
                className="statusicons"
                color="green"
                icon={faMagnifyingGlassLocation}
              />
              Cypher trace
            </article>

            <div className="piconbox">
              <CircularProgress color="secondary" size={picon} />
            </div>
          </div>{" "}
        </NoMaxWidthTooltip>{" "}
        <NoMaxWidthTooltip title="Social engineering intelligence gathering constructs and roleplay involving contact with target while posing as a potential victim">
          <div className="statusdetail">
            <article>
              <FontAwesomeIcon
                className="statusicons"
                color="rgba(0,0,0,0.6)"
                icon={faUsersRays}
              />
              Social engineering
            </article>

            <div className="piconbox">
              <CircularProgress color="secondary" size={picon} />
            </div>
          </div>
        </NoMaxWidthTooltip>
        <NoMaxWidthTooltip title="The use of cypher truelocator to bypass virtual private networks and proxies using cypher-trace metadata and social engineering constructs to build a true location profile">
          <div className="statusdetail">
            {" "}
            <article>
              <FontAwesomeIcon
                className="statusicons"
                color="red"
                icon={faLocationPinLock}
              />
              Cypher track
            </article>
            <div className="piconbox">
              <CircularProgress color="secondary" size={picon} />
            </div>
          </div>
        </NoMaxWidthTooltip>
        <NoMaxWidthTooltip title="Payment history analysis, identfication of bank account beneficary, cryptocurrency address tracking and build up of wallet identifiers using input data obtained from cypher trace and social engineering constructs">
          <div className="statusdetail">
            <article>
              <FontAwesomeIcon
                className="statusicons"
                color="green"
                icon={faMoneyBillTransfer}
              />
              Transaction analysis
            </article>{" "}
            <div className="piconbox">
              <CircularProgress color="secondary" size={picon} />
            </div>
          </div>
        </NoMaxWidthTooltip>
        <NoMaxWidthTooltip title="The use of all available datapoints, tracking information with the involvement of local law enforecement to pursue persecution and recovery of lost financial assets">
          <div className="statusdetail">
            <article>
              <FontAwesomeIcon
                className="statusicons"
                color="black"
                icon={faHandcuffs}
              />
              Cypher recovery
            </article>{" "}
            <div className="piconbox">
              <CircularProgress color="secondary" size={picon} />
            </div>
          </div>
        </NoMaxWidthTooltip>
      </div>
    </div>
  );
};

export default StatusInfo;
