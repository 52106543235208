import { Box, Button, Container, TextField, Typography } from "@mui/material";
import React, { useState } from "react";

import LandingHeader from "./LandingComponents/LandingHeader";
import { useNavigate } from "react-router";
import { resetPassword } from "../services/user";
import Loader2 from "../dashcomponents/pages/Loaders/Loader2";
import { useMediaQuery } from "react-responsive";

const ResetPassword = () => {
  const [email, setEmail] = useState("");
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const isMobile = useMediaQuery({ query: "(max-width: 768px)" });

  const handleSubmit = async () => {
    if (!email) return;
    setLoading(true);
    try {
      await resetPassword(email);
      navigate("/auth-email");
      // navigate("/auth00");
    } catch (ex) {
    } finally {
      setLoading(false);
    }
  };

  if (loading) {
    return (
      <div
        style={{
          display: "flex",
          minWidth: "100%",
          minHeight: "100vh",
          justifyContent: "center",
          alignItems: "center",
          background: "linear-gradient(135deg, #1d3557, #457b9d)",
        }}
      >
        <Loader2 />
      </div>
    );
  }

  return (
    <>
      <div className="mainlandingpage ">
        {" "}
        <LandingHeader />
        <div className="resetpassword" style={{ width: "90%" }}>
          <div
            className="resetpasswordbox"
            style={{ width: isMobile ? "100%" : "" }}
          >
            <Container maxWidth="xs">
              <Box
                sx={{
                  marginTop: 8,
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <Typography component="h5" variant="h5">
                  Search account via email
                </Typography>

                {/* Current Password Input */}

                {/* New Password Input */}
                <TextField
                  margin="normal"
                  required
                  fullWidth
                  label="Email"
                  type="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  sx={{ color: "white" }}
                  style={{ color: "white", backgroundColor: "white" }}
                />

                {/* Confirm New Password Input */}

                {/* Reset Password Button */}
                <Button
                  fullWidth
                  variant="contained"
                  color="primary"
                  sx={{ mt: 3, mb: 2 }}
                  onClick={handleSubmit}
                >
                  Confirm
                </Button>
              </Box>
            </Container>
          </div>
        </div>
      </div>
      ;
    </>
  );
};

export default ResetPassword;
