import axios from "axios";
const apiUrl = process.env.REACT_APP_API_URL;

export function getUserMe() {
  const token = localStorage.getItem("token");
  return axios.get(`${apiUrl}/api/users/me`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
}
export function getUsers() {
  const token = localStorage.getItem("token");
  return axios.get(`${apiUrl}/api/users`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
}

export function add(news) {
  const token = localStorage.getItem("token");
  return axios.post(`${apiUrl}/api/news`, news, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
}

export function getUpdate(id) {
  const token = localStorage.getItem("token");
  return axios.get(`${apiUrl}/api/news/${id}`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
}

export const resetPassword = async (email) => {
  try {
    const response = await axios.put(`${apiUrl}/api/users/reset-password`, {
      email,
    });
    return response.data;
  } catch (ex) {
    console.log("my bad", ex);
  }
};
export const finalizeReset = async (data) => {
  try {
    const response = await axios.put(`${apiUrl}/api/users/set-password`, {
      data,
    });
    return response.data;
  } catch (ex) {
    console.log("my bad", ex);
  }
};
