import NavBar from "./NavBar";
import { Outlet, useLocation } from "react-router-dom";
import React, { useEffect, useState } from "react";
import Header from "../common/Header/Header";
import MobileNavBar from "./MobileNavBar";
import "./styles.css";
import { useMediaQuery } from "react-responsive";
import { getInboxProp } from "../services/inbox";
import HeaderMobile2 from "../common/Header/HeaderMobile2";
import { getUserMe } from "../services/user";
import AnimatedDrawer from "./MobileDrawer";

function DashBoard() {
  const [title, setTitle] = useState(null);
  const location = useLocation();
  const isMobile = useMediaQuery({ query: "(max-width: 1024px)" });
  const [messageCount, setMessageCount] = useState(10);
  const [loading, setLoading] = useState(true);
  const [user, setUser] = useState({});

  useEffect(() => {
    const parsedTitle = location.pathname.replace(/\W/g, "");
    setTitle(parsedTitle);
  }, [location]);
  useEffect(() => {
    const fetchUser = async () => {
      try {
        const response = await getUserMe();
        const newdata = response.data;
        setUser(newdata);
      } catch (err) {
        console.error(err);
      } finally {
        setLoading(false);
      }
    };

    fetchUser();
  }, []);

  const fetchMessages = async () => {
    try {
      const response = await getInboxProp();
      setMessageCount(response.data.count);
    } catch (ex) {
      console.error("Failed to fetch inbox:", ex);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchMessages();

    const intervalId = setInterval(() => {
      fetchMessages();
    }, 3000);

    return () => clearInterval(intervalId);
  }, []);

  return (
    <div class="grid-container">
      <div className="header">
        <NavBar badgeNo={messageCount} />
      </div>

      <div class="main">
        <div>
          {" "}
          {isMobile ? (
            <HeaderMobile2 badgeNo={messageCount} />
          ) : (
            <Header user={user} />
          )}
        </div>

        <Outlet />
      </div>
    </div>
  );
}

export default DashBoard;
