import React from "react";
import {
  Box,
  Typography,
  Card,
  CardContent,
  Grid,
  Avatar,
} from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFolderOpen,
  faClock,
  faTimesCircle,
  faCheckCircle,
  faExclamationCircle,
} from "@fortawesome/free-solid-svg-icons";

const UserCaseStats = ({ caselist }) => {
  const pending = caselist?.filter((c) => c.status === "Pending") || [];
  const active = caselist?.filter((c) => c.status === "Active") || [];
  const rejected = caselist?.filter((c) => c.status === "Rejected") || [];

  const activeNo = active.length || 0;
  const pendingNo = pending.length || 0;
  const rejectedNo = rejected.length || 0;

  const stats = [
    {
      label: "Active Cases",
      value: activeNo,
      icon: faFolderOpen,
      color: "#4caf50",
    },
    {
      label: "Pending Cases",
      value: pendingNo,
      icon: faClock,
      color: "#ff9800",
    },
    {
      label: "Rejected Cases",
      value: rejectedNo,
      icon: faTimesCircle,
      color: "#f44336",
    },
    {
      label: "Successful Cases",
      value: 0,
      icon: faCheckCircle,
      color: "#2196f3",
    },
    {
      label: "Unsuccessful Cases",
      value: 0,
      icon: faExclamationCircle,
      color: "#9c27b0",
    },
  ];
  return (
    <Box
      sx={{
        backgroundColor: "#f4f6f8",
        padding: "20px", // Reduced padding
        borderRadius: "12px", // Slightly reduced border radius
        margin: "20px auto",
        boxShadow: "0px 6px 18px rgba(0, 0, 0, 0.1)", // Slightly lighter shadow
      }}
    >
      <Typography
        variant="h6" // Smaller heading
        sx={{
          textAlign: "center",
          fontWeight: "bold",
          marginBottom: "16px", // Reduced margin
          color: "#333",
        }}
      >
        User Case Overview
      </Typography>
      <Grid container spacing={2}>
        {" "}
        {/* Reduced spacing */}
        {stats.map((stat, index) => (
          <Grid item xs={12} sm={6} md={4} key={index}>
            <Card
              sx={{
                borderRadius: "10px", // Slightly reduced radius
                boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)", // Lighter shadow
                transition: "transform 0.3s ease, box-shadow 0.3s ease",
                "&:hover": {
                  transform: "translateY(-6px)", // Slightly reduced hover effect
                  boxShadow: "0px 8px 20px rgba(0, 0, 0, 0.15)", // Subtle hover shadow
                },
              }}
            >
              <CardContent
                sx={{
                  textAlign: "center",
                  padding: "16px", // Reduced padding
                }}
              >
                <Avatar
                  sx={{
                    backgroundColor: stat.color,
                    width: 48, // Smaller avatar
                    height: 48, // Smaller avatar
                    margin: "0 auto 8px", // Reduced margin
                  }}
                >
                  <FontAwesomeIcon
                    icon={stat.icon}
                    style={{ fontSize: "1.2rem", color: "#fff" }} // Smaller icon size
                  />
                </Avatar>
                <Typography
                  variant="body2" // Smaller label text
                  sx={{
                    fontWeight: "bold",
                    color: "#555",
                    fontSize: "0.875rem", // Reduced font size
                  }}
                >
                  {stat.label}
                </Typography>
                <Typography
                  variant="h6"
                  sx={{
                    fontWeight: "bold",
                    color: stat.color,
                    marginTop: "4px", // Reduced margin
                    fontSize: "1rem", // Smaller value text
                  }}
                >
                  {stat.value}
                </Typography>
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default UserCaseStats;
