import React, { useEffect, useState } from "react";
import { Typography, Card, CardContent, Box, Grid } from "@mui/material";
import { flags } from "../Admin/flags";

// Flag Mapping (adjust this list based on your available flags)

const TraceDataDisplay = ({ traceData1 }) => {
  const [traceData, setTraceData] = useState(0);
  const [counts, setCounts] = useState({
    totalCompleted: 0,
    totalActive: 0,
    countries: 0,
  });

  // Simulate Fetching Data
  useEffect(() => {
    setTraceData(traceData1); // Replace with actual API fetch
  }, [traceData]);

  // Count-Up Animation
  useEffect(() => {
    if (traceData) {
      const interval = 30; // Speed of increment
      const steps = 50; // Number of steps for animation
      const increments = {
        totalCompleted: traceData.totalCompleted / steps,
        totalActive: traceData.totalActive / steps,
        countries: traceData.countries / steps,
      };

      let currentStep = 0;

      const counter = setInterval(() => {
        if (currentStep >= steps) {
          clearInterval(counter);
          setCounts({
            totalCompleted: traceData.totalCompleted,
            totalActive: traceData.totalActive,
            countries: traceData.countries,
          });
        } else {
          setCounts((prevCounts) => ({
            totalCompleted: Math.min(
              traceData.totalCompleted,
              prevCounts.totalCompleted + increments.totalCompleted
            ),
            totalActive: Math.min(
              traceData.totalActive,
              prevCounts.totalActive + increments.totalActive
            ),
            countries: Math.min(
              traceData.countries,
              prevCounts.countries + increments.countries
            ),
          }));
          currentStep++;
        }
      }, interval);

      return () => clearInterval(counter);
    }
  }, [traceData]);

  if (!traceData) {
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          minHeight: "100vh",
        }}
      >
        <Typography variant="h6">Loading Data...</Typography>
      </Box>
    );
  }

  return (
    <Box
      sx={{
        backgroundColor: "#ffffff",
        padding: "30px",
        borderRadius: "10px",
        maxWidth: "1200px",
        margin: "1rem auto",
      }}
    >
      <Typography
        variant="h5"
        sx={{
          textAlign: "center",
          mb: 3,
          fontWeight: "bold",
          color: "rgba(0,0,0,0.6)",
        }}
      >
        Forensic History
      </Typography>

      <Grid container spacing={3} sx={{ textAlign: "center" }}>
        {/* Summary Cards */}
        <Grid item xs={12} sm={4}>
          <Card sx={{ boxShadow: 3, borderRadius: "8px" }}>
            <CardContent>
              <Typography variant="h6" sx={{ color: "#34495e" }}>
                Total Cases Completed
              </Typography>
              <Typography
                variant="h5"
                sx={{ fontWeight: "bold", color: "#27ae60" }}
              >
                {Math.floor(counts.totalCompleted || 0)}
              </Typography>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} sm={4}>
          <Card sx={{ boxShadow: 3, borderRadius: "8px" }}>
            <CardContent>
              <Typography variant="h6" sx={{ color: "#34495e" }}>
                Active Cases
              </Typography>
              <Typography
                variant="h5"
                sx={{ fontWeight: "bold", color: "#e74c3c" }}
              >
                {Math.floor(counts.totalActive || 0)}
              </Typography>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} sm={4}>
          <Card sx={{ boxShadow: 3, borderRadius: "8px" }}>
            <CardContent>
              <Typography variant="h6" sx={{ color: "#34495e" }}>
                Targeted Geo-locations
              </Typography>
              <Typography
                variant="h5"
                sx={{ fontWeight: "bold", color: "#2980b9" }}
              >
                {Math.floor(counts.countries || 0)}
              </Typography>
            </CardContent>
          </Card>
        </Grid>
      </Grid>

      <Typography variant="h6" sx={{ mt: 5, mb: 2, color: "#2c3e50" }}>
        Track Breakdown by Country
      </Typography>

      <Grid container spacing={3}>
        {traceData?.countryList?.map((country, index) => {
          const flag = flags?.find(([code]) => code === country.code);

          return (
            <Grid item xs={12} sm={6} md={4} key={index}>
              <Card
                sx={{
                  display: "flex",
                  alignItems: "center",
                  gap: 2,
                  padding: "10px",
                  boxShadow: 2,
                  borderRadius: "8px",
                  transition: "transform 0.3s ease, box-shadow 0.3s ease",
                  "&:hover": {
                    transform: "scale(1.05)",
                    boxShadow: 4,
                  },
                }}
              >
                <Typography variant="h4" sx={{ marginRight: "10px" }}>
                  <span className={flag[1]}></span>
                </Typography>
                <Box>
                  <Typography
                    variant="body1"
                    sx={{ fontWeight: "bold", color: "#34495e" }}
                  >
                    {country.code}
                  </Typography>
                  <Typography variant="body2" sx={{ color: "#7f8c8d" }}>
                    Cases: {country.cases.toLocaleString()}
                  </Typography>
                </Box>
              </Card>
            </Grid>
          );
        })}
      </Grid>
    </Box>
  );
};

export default TraceDataDisplay;
