import { Visibility, VisibilityOff } from "@mui/icons-material";
import React, { useState } from "react";
import { useNavigate, useParams } from "react-router";
import { finalizeReset } from "../services/user";
import Loader2 from "../dashcomponents/pages/Loaders/Loader2";
import { useMediaQuery } from "react-responsive";

const PasswordChange = () => {
  const navigate = useNavigate();
  const isMobile = useMediaQuery({ query: "(max-width: 768px)" });
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [error, setError] = useState("");
  const [partb, setPartb] = useState(false);
  const [loading, setLoading] = useState(false);

  const { id } = useParams();

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (newPassword !== confirmPassword) {
      setError("Passwords do not match!");
    } else {
      setError("");
      setLoading(true);

      const data = {
        password: newPassword,
        token: id,
      };

      try {
        const response = await finalizeReset(data);

        if (response) {
          setPartb(true);
          setLoading(false);
          setTimeout(() => {
            navigate("/login");
          }, 4000);
        } else {
          setLoading(false);
          setError("Invalid or expired token");
        }
      } catch (ex) {
        setError("Invalid or expired token");
      } finally {
        setLoading(false);
      }
    }
  };

  if (loading) {
    return (
      <div
        style={{
          display: "flex",
          minWidth: "100%",
          minHeight: "100vh",
          justifyContent: "center",
          alignItems: "center",
          background: "linear-gradient(135deg, #1d3557, #457b9d)",
        }}
      >
        <Loader2 />
      </div>
    );
  }

  return (
    <>
      {!partb && (
        <div className="mainlandingpage">
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              height: "100vh",
              width: "100%",
              padding: "20px",
              background: "linear-gradient(135deg, #1d3557, #457b9d)",
              color: "#ffffff",
              fontFamily: "'Poppins', sans-serif",
              textAlign: "center",
            }}
          >
            <div
              style={{
                background: "#ffffff",
                borderRadius: "16px",
                padding: "40px",
                width: isMobile ? "70%" : "30%",
                boxShadow: "0 10px 30px rgba(0, 0, 0, 0.2)",
              }}
            >
              <h1
                style={{
                  fontSize: "24px",
                  fontWeight: "bold",
                  marginBottom: "10px",
                  color: "#1d3557",
                }}
              >
                Change Password
              </h1>
              <form onSubmit={handleSubmit}>
                <div style={{ marginBottom: "20px", position: "relative" }}>
                  <input
                    type={showNewPassword ? "text" : "password"}
                    value={newPassword}
                    onChange={(e) => setNewPassword(e.target.value)}
                    placeholder="New Password"
                    style={{
                      width: "90%",
                      padding: "10px 40px 10px 10px",
                      fontSize: "16px",
                      border: "1px solid #ddd",
                      borderRadius: "8px",
                      outline: "none",
                    }}
                  />
                  <div
                    onClick={() => setShowNewPassword(!showNewPassword)}
                    style={{
                      position: "absolute",
                      top: "50%",
                      right: "10px",
                      transform: "translateY(-50%)",
                      cursor: "pointer",
                      color: "#555",
                    }}
                  >
                    {showNewPassword ? <VisibilityOff /> : <Visibility />}
                  </div>
                </div>
                <div style={{ marginBottom: "20px", position: "relative" }}>
                  <input
                    type={showConfirmPassword ? "text" : "password"}
                    value={confirmPassword}
                    onChange={(e) => setConfirmPassword(e.target.value)}
                    placeholder="Confirm Password"
                    style={{
                      width: "90%",
                      padding: "10px 40px 10px 10px",
                      fontSize: "16px",
                      border: "1px solid #ddd",
                      borderRadius: "8px",
                      outline: "none",
                    }}
                  />
                  <div
                    onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                    style={{
                      position: "absolute",
                      top: "50%",
                      right: "10px",
                      transform: "translateY(-50%)",
                      cursor: "pointer",
                      color: "#555",
                    }}
                  >
                    {showConfirmPassword ? <VisibilityOff /> : <Visibility />}
                  </div>
                </div>
                {error && (
                  <p
                    style={{
                      color: "red",
                      fontSize: "14px",
                      marginBottom: "20px",
                    }}
                  >
                    {error}
                  </p>
                )}
                <button
                  type="submit"
                  style={{
                    width: "100%",
                    padding: "10px",
                    fontSize: "16px",
                    color: "#ffffff",
                    background: "#1d3557",
                    border: "none",
                    borderRadius: "8px",
                    cursor: "pointer",
                  }}
                >
                  Update Password
                </button>
              </form>
            </div>
            <div
              style={{
                marginTop: "30px",
                animation: "fadeInUp 1.2s ease-out",
              }}
            >
              <p style={{ fontSize: "14px", color: "#ffffff" }}>
                Ensure your password is strong and easy to remember.
              </p>
            </div>
            <style>
              {`
          @keyframes fadeInUp {
            from {
              opacity: 0;
              transform: translateY(20px);
            }
            to {
              opacity: 1;
              transform: translateY(0);
            }
          }
        `}
            </style>
          </div>
        </div>
      )}
      {partb && (
        <div className="mainlandingpage">
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              height: "100vh",
              width: "100%",
              padding: "20px",
              background: "linear-gradient(135deg, #1d3557, #457b9d)",
              color: "#ffffff",
              fontFamily: "'Poppins', sans-serif",
              textAlign: "center",
            }}
          >
            <div
              style={{
                background: "#ffffff",
                borderRadius: "16px",
                padding: "40px",
                width: "70%",
                boxShadow: "0 10px 30px rgba(0, 0, 0, 0.2)",
              }}
            >
              <h1
                style={{
                  fontSize: "24px",
                  fontWeight: "bold",
                  marginBottom: "10px",
                  color: "#1d3557",
                }}
              >
                Password Changed
              </h1>
            </div>
            <div
              style={{
                marginTop: "30px",
                animation: "fadeInUp 1.2s ease-out",
              }}
            >
              <p style={{ fontSize: "14px", color: "#ffffff" }}>
                Page will redirect to login
              </p>
            </div>
            <style>
              {`
          @keyframes fadeInUp {
            from {
              opacity: 0;
              transform: translateY(20px);
            }
            to {
              opacity: 1;
              transform: translateY(0);
            }
          }
        `}
            </style>
          </div>
        </div>
      )}
    </>
  );
};

export default PasswordChange;
