import React, { useEffect, useState } from "react";
import { CircularProgress, Typography } from "@mui/material";
import { checkEmailStatus, resendLink } from "../../services/auth";
import { useNavigate } from "react-router";

const EmailSentNotification = () => {
  const [isResending, setIsResending] = useState(false);

  const [error, setError] = useState("");
  const [disabled, setDisabled] = useState(true);
  const [resendMessage, setResendMessage] = useState("");
  const navigate = useNavigate();

  useEffect(() => {
    const intervalId = setInterval(async () => {
      try {
        const response = await checkEmailStatus();
        if (response && response.isVerified) {
          localStorage.setItem("token", response.token);
          navigate("/account/home");
          clearInterval(intervalId);
        } else {
          console.log("Email is not confirmed yet.");
        }
      } catch (error) {
        console.error("Error checking email confirmation:", error);
        setError("There was an error verifying your email. Please try again.");
      }
    }, 5000); // Check every 5 seconds

    return () => clearInterval(intervalId); // Clean up interval on component unmount
  }, []);

  const handleResendCode = async () => {
    if (disabled) {
      setError("Wait a while before regenerating a new code");
      return;
    }

    setIsResending(true);

    const email = localStorage.getItem("email");
    try {
      await resendLink(email);
      setResendMessage("A new code has been sent to your email.");
      setDisabled(true);
    } catch (err) {
      setResendMessage("Failed to resend the code. Please try again later.");
    } finally {
      setIsResending(false);
    }
  };

  const isDisabled = () => {
    setTimeout(() => {
      setDisabled(false);
    }, 30000);
  };

  isDisabled();

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        height: "100vh",
        padding: "20px",
        background: "linear-gradient(135deg, #1d3557, #457b9d)",
        color: "#ffffff",
        fontFamily: "'Poppins', sans-serif",
        textAlign: "center",
      }}
    >
      <div
        style={{
          background: "#ffffff",
          borderRadius: "16px",
          padding: "40px",
          maxWidth: "500px",
          boxShadow: "0 10px 30px rgba(0, 0, 0, 0.2)",
        }}
      >
        <h1
          style={{
            fontSize: "24px",
            fontWeight: "bold",
            marginBottom: "10px",
            color: "#1d3557",
          }}
        >
          Email Sent!
        </h1>
        <p style={{ fontSize: "16px", color: "#555555", marginBottom: "20px" }}>
          A confirmation email has been sent to your inbox. Please check your
          email and confirm your account.
        </p>
        <p style={{ fontSize: "14px", color: "#888888", marginBottom: "30px" }}>
          Once your email is confirmed, you'll be automatically redirected to
          the next step.
        </p>

        {error && (
          <Typography variant="body2" sx={{ color: "red", mb: 2 }}>
            {error}
          </Typography>
        )}

        <button
          onClick={handleResendCode}
          disabled={disabled}
          style={{
            background: isResending
              ? "linear-gradient(135deg, #888, #aaa)"
              : "linear-gradient(135deg, #457b9d, #1d3557)",
            border: "none",
            color: "#ffffff",
            fontSize: "16px",
            fontWeight: "bold",
            padding: "12px 20px",
            borderRadius: "8px",
            cursor: isResending ? "not-allowed" : "pointer",
            transition: "all 0.3s ease",
          }}
        >
          {isResending ? (
            <CircularProgress
              size={20}
              style={{ color: "#ffffff", marginRight: "10px" }}
            />
          ) : (
            "Resend Email"
          )}
        </button>
      </div>
      <div
        style={{
          marginTop: "30px",
          animation: "fadeInUp 1.2s ease-out",
        }}
      >
        <p style={{ fontSize: "14px", color: "#ffffff" }}>
          Didn’t receive the email? Make sure to check your spam or junk folder.
        </p>
      </div>
      <style>
        {`
          @keyframes fadeInUp {
            from {
              opacity: 0;
              transform: translateY(20px);
            }
            to {
              opacity: 1;
              transform: translateY(0);
            }
          }
        `}
      </style>
    </div>
  );
};

export default EmailSentNotification;
