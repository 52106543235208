import axios from "axios";

const apiUrl = process.env.REACT_APP_API_URL;

export function login(email, password) {
  return axios.post(`${apiUrl}/api/auth`, {
    email,
    password,
  });
}

export function resetPassword(currentPassword, newPassword) {
  const token = localStorage.getItem("token"); // Get token from local storage

  return axios.patch(
    `${apiUrl}/api/change-password`, // No id in the URL
    {
      currentPassword,
      newPassword,
    },
    {
      headers: {
        Authorization: `Bearer ${token}`, // Include token in headers
      },
    }
  );
}

export const checkEmailStatus = async () => {
  try {
    const email = localStorage.getItem("email");

    if (!email) {
      console.error("Email not found");
      return;
    }

    const response = await axios.get(
      `${apiUrl}/api/auth/check-email-status?email=${encodeURIComponent(email)}`
    );

    console.log(response);

    return response.data;
  } catch (error) {
    console.error("Error checking email confirmation:", error);
  }
};

export const resendLink = async (email) => {
  try {
    const response = await axios.put(`${apiUrl}/api/users/resend-link`, {
      email,
    });
    return response.data;
  } catch (ex) {
    console.log("my bad", ex);
  }
};

export const confirm = async (id) => {
  try {
    const response = await axios.get(`${apiUrl}/api/auth/verify-email/${id}`);
    return response.data;
  } catch (ex) {
    console.log("my bad", ex);
  }
};
