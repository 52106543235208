import React, { useState } from "react";
import "./styles.css";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import AnalyticsCard from "./AnalyticsCard";
import AnalyticsSocial from "./AnalyticsSocial";
import AnalyticsForensics from "./AnalyticsForensics";
import { useMediaQuery } from "react-responsive";
import { Typography } from "@mui/material";

const AnalyticsMain = ({ caselist }) => {
  const isMobile = useMediaQuery({ query: "(max-width: 768px)" });
  const [selectedValue, setSelectedValue] = useState("");
  const [selectedCase, setSelectedCase] = useState(null);

  const handleChange = (event) => {
    const caseValue = event.target.value;
    setSelectedValue(caseValue);

    const caseData = caselist.find((c) => c.value === caseValue);
    setSelectedCase(caseData);
  };

  const mobStyle = {
    borderRadius: "16px",
    boxShadow: "0 4px 20px rgba(0, 0, 0, 0.2)",
    backgroundColor: "inherit",
    width: "90%",
  };

  return (
    <div
      className="analyticsmain "
      style={{
        minWidth: isMobile ? "100%" : "",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      <div className="statusheader" style={isMobile ? mobStyle : null}>
        <FormControl fullWidth>
          <InputLabel id="demo-simple-select-label">Select Case</InputLabel>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={selectedValue}
            label="Select Case"
            onChange={handleChange}
            placeholder="Select Case"
          >
            {caselist &&
              caselist.map((c) => (
                <MenuItem key={c.id} value={c.value}>
                  {c.name}
                </MenuItem>
              ))}
          </Select>
        </FormControl>
      </div>

      {!selectedCase && (
        <div
          style={{
            boxShadow: "0 4px 20px rgba(0, 0, 0, 0.2)",

            backgroundColor: "inherit",

            minHeight: "10rem",
            width: "90%",
            marginTop: "10%",
            justifyContent: "center",
            alignItems: "center",
            display: "flex",
          }}
        >
          <div>
            <Typography variant="body1">
              Select a case to get started
            </Typography>
          </div>
        </div>
      )}
      {selectedCase && <AnalyticsCard />}
      {selectedCase && (
        <AnalyticsSocial
          socialmedia={selectedCase ? selectedCase.socialmedia : null}
          mapdata={selectedCase.geodata.geomapdata}
          ip={selectedCase.geodata.geoip}
          coordinates={selectedCase.geodata.geocoordinates}
          className={selectedCase.geodata.geoclassname}
        />
      )}

      {selectedCase && (
        <AnalyticsForensics
          blockchaintype={selectedCase.blockchaintypes}
          pk={selectedCase.pk}
          date={selectedCase.datestarted}
        />
      )}
    </div>
  );
};

export default AnalyticsMain;
