// Footer.js
import React, { useState } from "react";
import styled from "styled-components";
import {
  faFacebook,
  faLinkedin,
  faSquareInstagram,
  faWhatsapp,
} from "@fortawesome/free-brands-svg-icons";
import { faCopyright } from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { TextField, Button } from "@mui/material";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { addEmail } from "../../services/landing";

// Styled components
const FooterContainer = styled.div`
  background-color: #f8f8f8;
  padding: 1.5rem 1rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-family: "Helvetica", sans-serif;
`;

const FooterDetails = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 400px;
  margin-bottom: 1.5rem;
`;

const FooterSection = styled.div`
  margin-bottom: 1rem;
`;

const SectionHeader = styled.h5`
  font-weight: bold;
  margin-bottom: 0.5rem;
  color: #0077ff;
`;

const FooterLink = styled(Link)`
  text-decoration: none;
  color: #333;
  font-size: 0.9rem;
  margin: 0.2rem 0.5rem;
  &:hover {
    color: #0077ff;
  }
`;

const NewsletterSection = styled.div`
  margin-top: 1rem;
  text-align: center;
`;

const SubscribeText = styled.p`
  font-size: 0.9rem;
  color: #666;
`;

const FooterCC = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: 1rem 0;
  border-top: 1px solid #e2e2e2;
`;

const CopyrightText = styled.div`
  display: flex;
  align-items: center;
  font-size: 0.8rem;
  color: #666;
`;

const SocialsContainer = styled.div`
  display: flex;
  align-items: center;
  font-size: 0.8rem;
  color: #0077ff;
`;

const SocialIcon = styled.span`
  margin: 0 0.5rem;
  cursor: pointer;
  &:hover {
    color: #0056b3;
  }
`;

const LandingFooterMobile = () => {
  const [formData, setFormData] = useState({
    email: "",
  });
  const [loading, setLoading] = useState(false);

  const handleChange = (event) => {
    const { name, value } = event.target;

    // Check if it's logging the correct value
    console.log("Field Name:", name);
    console.log("Field Value:", value);

    // Update the formData state
    setFormData((prev) => ({
      ...prev,
      [name]: value, // This will update the email field in formData
    }));
  };

  const validateEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const handleSubmit = async () => {
    if (!validateEmail(formData.email)) {
      toast.error("Please enter a valid email address");
      return;
    }
    try {
      setLoading(true);
      await addEmail(formData);
      toast.success("Email added successfully");
      setFormData({ email: "" }); // Clear input after success
    } catch (ex) {
      toast.error("Something went wrong. Please try again.");
    } finally {
      setLoading(false); // Stop the loading spinner after request is done
    }
  };
  return (
    <FooterContainer>
      <FooterDetails>
        <FooterSection>
          <SectionHeader>Information</SectionHeader>
          <FooterLink to="/blog">About</FooterLink>
          <FooterLink to="/disclaimer">Disclaimer</FooterLink>
        </FooterSection>

        <FooterSection>
          <SectionHeader>Help</SectionHeader>

          <a
            style={{
              textDecoration: "none",
              color: "#333",
              fontSize: "0.9rem",
              margin: "0.2rem 0.5rem",
            }}
            href="mailto:support@cypherforensics.com"
          >
            {" "}
            Customer Support
          </a>

          <FooterLink to="/privacy">Privacy Policy</FooterLink>
          <FooterLink to="/terms">Terms & Conditions</FooterLink>
        </FooterSection>

        <NewsletterSection>
          <SectionHeader>Subscribe to our newsletter</SectionHeader>
          <SubscribeText>
            Protect yourself by getting updates on the latest fraud practices
          </SubscribeText>
          <div style={{ display: "flex", width: "100%" }}>
            <TextField
              style={{ flexGrow: 1 }}
              size="small"
              placeholder="Enter email address"
              variant="outlined"
              name="email" // This is important for identifying the field
              value={formData.email}
              onChange={handleChange}
            />
            <Button
              variant="contained"
              color="primary"
              onClick={handleSubmit}
              disabled={loading || !formData.email} // Disable during loading or if email is empty
              aria-busy={loading}
            >
              Sign Up
            </Button>
          </div>
        </NewsletterSection>
      </FooterDetails>

      <FooterCC>
        <CopyrightText>
          <FontAwesomeIcon icon={faCopyright} />
          <span style={{ marginLeft: "0.5rem" }}>
            Copyright 2025. All Rights Reserved by Cypher Forensics
          </span>
        </CopyrightText>
        <SocialsContainer>
          Follow us on:
          <SocialIcon>
            <FontAwesomeIcon icon={faLinkedin} />
          </SocialIcon>
          <SocialIcon>
            <FontAwesomeIcon icon={faFacebook} />
          </SocialIcon>
          <SocialIcon>
            <FontAwesomeIcon icon={faSquareInstagram} />
          </SocialIcon>
          <SocialIcon>
            <FontAwesomeIcon icon={faWhatsapp} />
          </SocialIcon>
        </SocialsContainer>
      </FooterCC>
    </FooterContainer>
  );
};

export default LandingFooterMobile;
