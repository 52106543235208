import React from "react";
import GridWrapper from "../../../common/GridWrapper/GridWrapper2";
import InboxWideMobile from "./InboxComponents/InboxWideMobile";
import { useNavigate } from "react-router";
import Typography from "@mui/material/Typography";

const InboxMobile = ({ messages }) => {
  const navigate = useNavigate();

  if (messages.length == 0) {
    return (
      <Typography
        align="center"
        sx={{
          margin: "40px 16px",
          color: "rgba(0, 0, 0, 0.6)",
          fontSize: "1.3rem",
        }}
      >
        Inbox empty
      </Typography>
    );
  }

  return (
    <div
      style={{
        position: "relative",
        padding: "0.2rem 0.1rem 0.3rem 0.1rem",
        minHeight: "calc(100vh - 1px)",

        minWidth: "100%",
        marginTop: "0.1rem",
        marginLeft: "0rem",
        display: "flex",
        flexDirection: "column",

        alignItems: "center",
      }}
    >
      <div className="mobileinboxmain">
        {messages.map((message) => (
          <InboxWideMobile
            key={message._id}
            sender={message.sender}
            subject={message.subject}
            src={message.src}
            date={message.date}
            id={message._id}
            onClick={() => navigate(`/account/inbox/${message._id}`)}
            isNew={message.new}
          />
        ))}
      </div>
    </div>
  );
};

export default InboxMobile;
