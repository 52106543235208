import React from "react";
import "./styles.css";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";
import { Link } from "react-router-dom";

const StatusLocationMobile = ({
  trackedip,
  ispname,
  osname,
  coordinates,
  mapdata,
  geoip,
  geoispname,
  geocoordinates,
  geomapdata,
  geoosname,
  classname,
  geoclassname,
  location,
  geolocation,
}) => {
  const mobilestyles = {
    fontSize: "0.7rem",
    textWrap: "nowrap",
    overflow: "hidden",
  };
  return (
    <div
      className="statuslocationmobile"
      style={{
        borderRadius: "16px",
        boxShadow: "0 4px 20px rgba(0, 0, 0, 0.2)",
      }}
    >
      <div className="statuslocationboxinnermobile">
        <div className="statuslocationboxmobile">
          <Table>
            <TableHead>
              <TableRow>
                <TableCell sx={mobilestyles}>Location</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell sx={mobilestyles}>IP</TableCell>
                <TableCell sx={mobilestyles} className="locationmarginmobile">
                  {trackedip || <div className="notavailable">NA</div>}{" "}
                  <span className={classname}></span>{" "}
                  {trackedip && <Link to={mapdata}>{location}</Link>}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell sx={mobilestyles}>ISP</TableCell>
                <TableCell sx={mobilestyles}>
                  {ispname || <div className="notavailable">NA</div>}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell sx={mobilestyles}>OS</TableCell>
                <TableCell sx={mobilestyles}>
                  {osname || <div className="notavailable">NA</div>}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell sx={mobilestyles}>Geo-tag</TableCell>
                <TableCell sx={mobilestyles}>
                  {coordinates || <div className="notavailable">NA</div>}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell sx={mobilestyles}>Geo Location</TableCell>
                <TableCell sx={mobilestyles} className="mapdatatabmobile">
                  {(
                    <Link className="mobilemapdata" to={mapdata}>
                      <p style={{ overflow: "hidden", maxWidth: "15rem" }}>
                        {mapdata}
                      </p>
                    </Link>
                  ) || <div className="notavailable">Not available</div>}
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </div>
        <div className="statuslocationboxmobile">
          <Table>
            <TableHead>
              <TableRow>
                <TableCell sx={mobilestyles}>True location</TableCell>
              </TableRow>
            </TableHead>

            <TableBody>
              <TableRow>
                <TableCell sx={mobilestyles}>IP</TableCell>
                <TableCell className="locationmarginmobile" sx={mobilestyles}>
                  {geoip || <div className="notavailable">NA</div>}{" "}
                  <span className={geoclassname}></span>
                  {geoip && <Link to={geomapdata}>{geolocation}</Link>}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell sx={mobilestyles}>ISP</TableCell>
                <TableCell sx={mobilestyles}>
                  {geoispname || <div className="notavailable">NA</div>}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell sx={mobilestyles}>OS</TableCell>
                <TableCell sx={mobilestyles}>
                  {geoosname || <div className="notavailable">NA</div>}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell sx={mobilestyles}>Geo-tag</TableCell>
                <TableCell sx={mobilestyles}>
                  {geocoordinates || <div className="notavailable">NA</div>}{" "}
                  <br />
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell sx={mobilestyles}>Geo Location</TableCell>
                <TableCell sx={mobilestyles} className="mapdatamobile">
                  {(
                    <Link className="mobilemapdata" to={geomapdata}>
                      <p style={{ overflow: "hidden", maxWidth: "15rem" }}>
                        {" "}
                        {geomapdata}
                      </p>
                    </Link>
                  ) || <div className="notavailable">pending</div>}
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </div>
      </div>
    </div>
  );
};

export default StatusLocationMobile;
