import React, { useEffect, useState } from "react";
import { Typography } from "@mui/material";
import { confirm } from "../../services/auth";
import { useNavigate, useParams } from "react-router";

const Confirmation = () => {
  const [error, setError] = useState("");
  const navigate = useNavigate();

  const [data, setData] = useState("");
  const [loading, setLoading] = useState(true);

  const { id } = useParams();

  useEffect(() => {
    const fetchStatus = async () => {
      try {
        const res = await confirm(id);

        if (res) {
          localStorage.setItem("token", res);
          setData("Confirmed");
          setError("App would redirect soon");
          setTimeout(() => navigate("/account/home"), 3000); // Delay redirection by 3 seconds
        }
      } catch (ex) {
        setData("Verification Failed");
        setError("Invalid or Expired Token");
      } finally {
        setLoading(false);
      }
    };

    fetchStatus();
  }, [id]);

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        height: "100vh",
        padding: "20px",
        background: "linear-gradient(135deg, #1d3557, #457b9d)",
        color: "#ffffff",
        fontFamily: "'Poppins', sans-serif",
        textAlign: "center",
      }}
    >
      <div
        style={{
          background: "#ffffff",
          borderRadius: "16px",
          padding: "40px",
          maxWidth: "500px",
          boxShadow: "0 10px 30px rgba(0, 0, 0, 0.2)",
        }}
      >
        <h2
          style={{
            fontSize: "24px",
            fontWeight: "bold",
            marginBottom: "10px",
            color: "#1d3557",
          }}
        >
          Checking Status
        </h2>

        <Typography
          variant="h5"
          sx={{ mb: 2, color: "#00b5ff", fontWeight: "bold" }}
        >
          {data ? data : "Checking.."}
        </Typography>

        {error && (
          <Typography variant="body2" sx={{ mb: 3, color: "#00b5ff" }}>
            {error}
          </Typography>
        )}
      </div>

      <div
        style={{
          marginTop: "30px",
          animation: "fadeInUp 1.2s ease-out",
        }}
      ></div>
      <style>
        {`
          @keyframes fadeInUp {
            from {
              opacity: 0;
              transform: translateY(20px);
            }
            to {
              opacity: 1;
              transform: translateY(0);
            }
          }
        `}
      </style>
    </div>
  );
};

export default Confirmation;
